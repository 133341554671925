import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Button,
} from '@mui/material';
import { useProfile } from '../../hooks/useProfile.ts';
import { DataControlsForm } from './DataControlsForm.tsx';

const DataControlsInterstitial = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState('');
  const [customSharingText, setCustomSharingText] = useState('');
  const { updateDataControl } = useProfile();

  const handleSave = async () => {
    await updateDataControl({
      sharing_status: selectedOption,
      details: selectedOption === 'custom' ? customSharingText : undefined
    });
    navigate('/dashboard');
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Paper elevation={2} sx={{ p: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Configure Data Access Controls
        </Typography>
        
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }} align="center">
          Your information is completely locked by default. Choose how you'd like to share your profile with opportunities.
        </Typography>

        <DataControlsForm
          selectedOption={selectedOption}
          onOptionChange={setSelectedOption}
          customText={customSharingText}
          onCustomTextChange={setCustomSharingText}
        />

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
          <Button
            onClick={handleSave}
            variant="contained"
            size="large"
            disabled={!selectedOption}
          >
            Continue to Dashboard
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default DataControlsInterstitial;