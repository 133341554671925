import axios from 'axios';
import { useAuth } from './useAuth.ts';
import { useEffect, useMemo } from 'react';

const useAPIWithAuth = () => {
  const { logout } = useAuth();

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: '/api',
    });
  }, []);

  useEffect(() => {
    const setInterceptor = async () => {
      axiosInstance.interceptors.request.use(
        async (config) => {
          const accessTokenToUse = localStorage.getItem('auth_token');
          config.headers['Authorization'] = `Bearer ${accessTokenToUse}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );

      axiosInstance.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 403) {
            logout({
              returnTo: window.location.origin,
            });
          }
          return Promise.reject(error);
        }
      );
    };

    setInterceptor();

    return () => {
      axiosInstance.interceptors.request.eject(setInterceptor);
      axiosInstance.interceptors.response.eject(setInterceptor);
    };
  }, [logout, axiosInstance]);

  return axiosInstance;
};

export default useAPIWithAuth;
