import React from 'react';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
  Typography,
  ListItem
} from '@mui/material';
import {
  ShieldCheck as SecurityIcon,
  PencilLine as EditNoteIcon,
  Lock as LockIcon
} from 'lucide-react';

export const DataControlsForm = ({ 
  selectedOption, 
  onOptionChange, 
  customText = '', 
  onCustomTextChange 
}) => {
  return (
    <RadioGroup
      value={selectedOption}
      onChange={(e) => onOptionChange(e.target.value)}
    >
      <ListItem sx={{ mb: 3, display: 'block' }}>
        <FormControlLabel
          value="standard"
          control={<Radio />}
          label={
            <Box sx={{ ml: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <SecurityIcon size={20} style={{ marginRight: '8px' }} />
                <Typography variant="h6">Trusted Network (Recommended)</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Share with our vetted firms, all of whom have signed NDAs, to maximise exposure to relevant opportunities.
              </Typography>
            </Box>
          }
        />
      </ListItem>

      <ListItem sx={{ mb: 3, display: 'block' }}>
        <FormControlLabel
          value="custom"
          control={<Radio />}
          label={
            <Box sx={{ ml: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EditNoteIcon size={20} style={{ marginRight: '8px' }} />
                <Typography variant="h6">Custom Arrangement</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Specify your custom data sharing preferences
              </Typography>
            </Box>
          }
        />
        {selectedOption === 'custom' && (
          <TextField
            fullWidth
            value={customText}
            onChange={(e) => onCustomTextChange(e.target.value)}
            placeholder="Enter firm types, separated by commas (e.g. headhunters, private equity)"
            variant="outlined"
            multiline
            rows={4}
            sx={{ mt: 2, ml: 4 }}
          />
        )}
      </ListItem>

      <ListItem sx={{ mb: 3, display: 'block' }}>
        <FormControlLabel
          value="locked"
          control={<Radio />}
          label={
            <Box sx={{ ml: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <LockIcon size={20} style={{ marginRight: '8px' }} />
                <Typography variant="h6">Maintain Full Lockdown</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary">
                Keep all access closed, visible only to you (not recommended).
              </Typography>
            </Box>
          }
        />
      </ListItem>
    </RadioGroup>
  );
};