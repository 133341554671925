import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const formatDate = (dateString) => {
  if (!dateString) return '';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'current';
    return date.toLocaleDateString('default', { month: 'short', year: 'numeric' });
  } catch {
    return dateString;
  }
};

const RolesList = ({ roles }) => (
  <List disablePadding>
    {roles.map((role, index) => (
      <ListItem 
        key={role.id || index} 
        disableGutters 
        sx={{ py: 0.5 }}
      >
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="subtitle2" sx={{ lineHeight: 1.2 }}>
                {role.title}
              </Typography>{console.log(role)}
              {role.is_primary && (
                <Typography 
                  variant="caption" 
                  sx={{ 
                    bgcolor: 'primary.main', 
                    color: 'primary.contrastText',
                    px: 0.75,
                    py: 0.25,
                    borderRadius: 1,
                    lineHeight: 1,
                    fontSize: '0.65rem',
                    textTransform: 'uppercase',
                    letterSpacing: '0.05em'
                  }}
                >
                  Primary
                </Typography>
              )}
            </Box>
          }
          secondary={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.75, flexWrap: 'wrap' }}>
              <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                {role.organisation}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                •
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                {formatDate(role.start_date)} - {formatDate(role.end_date)}
              </Typography>
              {role.commitment && (
                <>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                    •
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.2 }}>
                    {role.commitment}
                  </Typography>
                </>
              )}
            </Box>
          }
          sx={{ my: 0 }}
        />
      </ListItem>
    ))}
  </List>
);

const ExperienceList = ({ experiences, expandable = true }) => {
  const [isExpanded, setIsExpanded] = useState(!expandable);

  if (!experiences?.length) return null;

  // Sort roles by date, putting current roles first
  const sortedRoles = [...experiences].sort((a, b) => {
    const aIsCurrent = !a.end_date || a.end_date.toLowerCase() === 'current';
    const bIsCurrent = !b.end_date || b.end_date.toLowerCase() === 'current';
    if (aIsCurrent && !bIsCurrent) return -1;
    if (!aIsCurrent && bIsCurrent) return 1;
    return 0;
  });

  // Get first 5 roles and remaining count
  const visibleRoles = sortedRoles.slice(0, 5);
  const remainingCount = sortedRoles.length - 5;
  
  // Get unique organizations from remaining roles
  const remainingOrgs = [...new Set(sortedRoles.slice(5).map(exp => exp.organisation))];
  const topRemainingOrgs = remainingOrgs.slice(0, 2);
  const remainingOrgCount = remainingOrgs.length - 2;
  
  const summaryText = remainingCount > 0 
    ? `and ${remainingCount} more previous ${remainingCount === 1 ? 'role' : 'roles'} including ${topRemainingOrgs.join(', ')}${remainingOrgCount > 0 ? ` and ${remainingOrgCount} ${remainingOrgCount === 1 ? 'other' : 'others'}` : ''}`
    : '';

  return (
    <>
      {expandable ? (
        <>
          {!isExpanded ? (
            <>
              <RolesList roles={visibleRoles} />
              {remainingCount > 0 && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {summaryText}
                </Typography>
              )}
            </>
          ) : (
            <RolesList roles={sortedRoles} />
          )}
          {sortedRoles.length > 5 && (
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ mt: 1 }}
            >
              {isExpanded ? 'Show Less' : 'Show All'}
            </Button>
          )}
        </>
      ) : (
        <RolesList roles={sortedRoles} />
      )}
    </>
  );
};

export default ExperienceList;