import React from 'react';

const LogoIcon = ({ size = 24, color = '#e5261f', ...props }) => (
  <svg 
    viewBox="30 20 150 110" 
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    {...props}
  >
    <title>logo-mobile</title>
    <path 
      d="M 40,75 C 40,35 70,35 80,75 C 90,115 120,115 130,75 C 140,35 170,35 170,75 C 170,115 140,115 130,75 C 120,35 90,35 80,75 C 70,115 40,115 40,75 Z"
      fill="none" 
      stroke={color} 
      strokeWidth="8"
    />
  </svg>
);

export default LogoIcon;
