import React from 'react';
import { MessageList } from './MessageList.tsx';
import { ChatInput } from './ChatInput.tsx';
import { ConnectionStatus } from './ConnectionStatus.tsx';
import { Box } from '@mui/material';
export const ChatUI = ({
  messages,
  isConnected,
  isLoading,
  initialLoadComplete,
  onSendMessage,
  isMobile
}) => {
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}>
      <ConnectionStatus isConnected={isConnected} />
      
      <Box sx={{ 
        flex: 1,
        overflow: 'auto',
        px: 2
      }}>
        <MessageList
          messages={messages}
          isMobile={isMobile}
          isLoading={isLoading}
        />
      </Box>
      
      <Box sx={{ p: 2, mt: 'auto' }}>
        <ChatInput 
          onSendMessage={onSendMessage}
          isLoading={isLoading}
          initialLoadComplete={initialLoadComplete}
        />
      </Box>
    </Box>
  );
};