import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { useProfile } from '../../hooks/useProfile.ts';
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Settings as SettingsIcon,
  Key as EditNoteIcon } from 'lucide-react';
import { DataControlsForm } from './DataControlsForm.tsx';
import { AdaptiveSectionLayout } from '../profile/sections/AdaptiveSectionLayout.tsx';

const DataControlsDialog = ({ open, onClose, onSave, currentOption, customText = '', isLoading = false }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [customSharingText, setCustomSharingText] = useState('');
  
  useEffect(() => {
    if (open) {
      setSelectedOption(currentOption || '');
      setCustomSharingText(customText || '');
    }
  }, [open, currentOption, customText]);

  const handleSave = () => {
    onSave({
      sharing_status: selectedOption,
      details: selectedOption === 'custom' ? customSharingText : undefined
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Data Access Controls</DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Your information is completely locked by default.
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          Choose how you'd like to share your profile with opportunities:
        </Typography>
        <DataControlsForm
          selectedOption={selectedOption}
          onOptionChange={setSelectedOption}
          customText={customSharingText}
          onCustomTextChange={setCustomSharingText}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>Cancel</Button>
        <Button 
          onClick={handleSave}
          variant="contained" 
          disabled={isLoading || !selectedOption}
        >
          {isLoading ? 'Saving...' : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DataControlsBanner = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { profile, updateDataControl } = useProfile();
  const dataControl = profile?.data_control;

  const getBannerProps = () => {
    if (!dataControl || !dataControl.sharing_status) {
      return {
        status: 'unset',
        icon: LockIcon,
        title: 'NOT CONFIGURED',
        description: 'Configure who can see your profile',
        color: 'text.secondary'
      };
    }

    switch (dataControl.sharing_status) {
      case 'standard':
        return {
          status: 'standard',
          icon: LockOpenIcon,
          title: 'STANDARD ACCESS',
          description: "Profile is visible to verified search firms and hiring organizations that have signed an NDA",
          color: 'text.secondary'
        };
      case 'custom':
        return {
          status: 'custom',
          icon: EditNoteIcon,
          title: 'CUSTOM ACCESS',
          description: dataControl.details || "Custom access configured",
          color: 'error.main'
        };
      case 'locked':
        return {
          status: 'locked',
          icon: LockIcon,
          title: 'LOCKED',
          description: "Data is completely private - no third parties can see any of your information",
          color: 'text.secondary'
        };
      default:
        return getBannerProps();
    }
  };

  const handleSave = async (newSettings) => {
    await updateDataControl(newSettings);
    setModalOpen(false);
  };

  const bannerProps = getBannerProps();
  const Icon = bannerProps.icon;

  const sections = [
    {
      icon: Icon,
      title: "Data Access Controls",
      content: (
        <Box sx={{ width: '100%' }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          width: '100%',
          '@container (min-width: 400px)': {
            flexDirection: 'row',
            justifyContent: 'space-between'
          }
        }}>
            <Box>
              <Typography variant="subtitle2" color={bannerProps.color}>
                {bannerProps.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {bannerProps.description}
              </Typography>
            </Box>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setModalOpen(true)}
              startIcon={<SettingsIcon size={16} />}
              sx={{ 
                alignSelf: {
                  '@container (min-width: 400px)': 'flex-start'
                }
              }}
            >
              Manage settings
            </Button>
          </Box>
        </Box>
      )
    }
  ];

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          mb: 2,
          bgcolor: 'grey.50',
          borderLeft: 4,
          borderColor: 'primary.main'
        }}
      >
        <AdaptiveSectionLayout sections={sections} />
      </Paper>
      
      <DataControlsDialog 
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSave}
        currentOption={dataControl?.sharing_status || ''}
        customText={dataControl?.details || ''}
      />
    </>
  );
};

export default DataControlsBanner;