import React from 'react';
import { Paper, Button, Box, Typography } from '@mui/material';
import { UserCheck as UserCheckIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { AdaptiveSectionLayout } from './AdaptiveSectionLayout.tsx';

const OnboardingCTA = () => {
  const sections = [
    {
      icon: UserCheckIcon,
      title: "Complete Your Profile",
      content: (
        <Box sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { sm: 'space-between' },
          gap: 2
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Your profile is incomplete. Take a few minutes to fill it out so headhunters 
              and internal search teams can match you better.
            </Typography>
          </Box>
          <Box sx={{ 
            display: 'flex',
            justifyContent: { xs: 'flex-start', sm: 'flex-end' }
          }}>
            <Button
              component={Link}
              to="/onboarding"
              variant="contained"
              size="small"
              sx={{ 
                textTransform: 'uppercase',
                whiteSpace: 'nowrap',
                maxHeight: 36
              }}
            >
              Complete Profile Now
            </Button>
          </Box>
        </Box>
      )
    }
  ];

  return (
    <Paper 
      elevation={0} 
      sx={{ 
        p: 2,
        mb: 2,
        bgcolor: 'grey.50',
        borderLeft: 4,
        borderColor: 'primary.main'
      }}
    >
      <AdaptiveSectionLayout sections={sections} />
    </Paper>
  );
};

export default OnboardingCTA;