import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './providers/AuthProvider.tsx';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAnalytics } from './analytics/useAnalytics.ts';
import { usePageTracking } from './analytics/tracking.ts';
import { ProtectedRoute } from './components/routing/ProtectedRoute.tsx';
import { PublicRoute } from './components/routing/PublicRoute.tsx';
import { InviteRoute } from './components/routing/InviteRoute.tsx';
import theme from './theme.ts';
import PageLayout from './components/PageLayout.tsx';
import { NAV_ROUTES_ARRAY, AUTH_ROUTES } from './routes.ts';

const queryClient = new QueryClient();

const MainContent = () => {
  usePageTracking();
  useAnalytics();
  return (
    <Routes>
      {/* Public routes */}
      {Object.values(AUTH_ROUTES)
        .filter(route => route.public)
        .map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <PublicRoute>
                <route.Element {...(route.props || {})} />
              </PublicRoute>
            }
          />
        ))}

      {/* Invite-only routes */}
      {Object.values(AUTH_ROUTES)
        .filter(route => route.requiresInvite)
        .map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <InviteRoute>
                <route.Element {...(route.props || {})} />
              </InviteRoute>
            }
          />
        ))}

      {/* Protected routes */}
      {Object.values(AUTH_ROUTES)
        .filter(route => !route.public && !route.requiresInvite)
        .map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute requiresAccount={route.requiresAccount}>
                <PageLayout>
                  <route.Element {...(route.props || {})} />
                </PageLayout>
              </ProtectedRoute>
            }
          />
        ))}

      {/* Navigation routes */}
      <Route
        element={
          <ProtectedRoute>
            <PageLayout>
              <Outlet />
            </PageLayout>
          </ProtectedRoute>
        }
      >
        {NAV_ROUTES_ARRAY.map(route => (
          <Route
            key={route.path}
            path={route.path}
            element={<route.Component />}
          />
        ))}
      </Route>

      {/* Fallback redirect */}
      <Route path="*" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
};

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');
const root = createRoot(rootElement);
root.render(
  <AuthProvider>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainContent />
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </AuthProvider>
);