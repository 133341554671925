import React from 'react';
import QuadrantMenu from '../components/QuadrantMenu.tsx';
import { Typography, Box } from '@mui/material';
import { useAuth } from '../hooks/useAuth.ts';

const getTimeBasedGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good morning';
  if (hour < 18) return 'Good afternoon';
  return 'Good evening';
};

const Dashboard = () => {
  const { user } = useAuth();
  const firstName = user?.given_name || user?.nickname || '';
  
  return (
    <>
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          my: 4,
          gap: 2
        }}
      >
        <img 
          src="/logo-mobile.svg" 
          alt="Nexco.ai Logo" 
          style={{ 
            height: '40px',
            width: 'auto'
          }}
        />
        <Typography 
          component="h1" 
          variant="h3"
          sx={{ 
            fontFamily: '"Cormorant Garamond", serif',
            fontWeight: 100,
            color: 'text.primary',
          }}
        >
          {`${getTimeBasedGreeting()} ${firstName}`}
        </Typography>
      </Box>
      <QuadrantMenu />
    </>
  );
};

export default Dashboard;