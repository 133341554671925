import React from 'react';
import { Container, Typography, Box, Paper, List, ListItem } from '@mui/material';

const PolicyContainer = ({ children }) => (
  <Container maxWidth="md" sx={{ py: 4 }}>
    <Paper elevation={1} sx={{ p: 4 }}>
      <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
        {children}
      </Box>
    </Paper>
  </Container>
);

const ListItemStyled = ({ children }) => (
  <ListItem sx={{ display: 'list-item', listStyleType: 'disc', pl: 0, py: 0.5 }}>
    <Typography>{children}</Typography>
  </ListItem>
);

export const PrivacyPolicy = () => (
  <PolicyContainer>
    <Typography variant="h4" component="h1" gutterBottom>
      Privacy Policy
    </Typography>
    
    <Typography paragraph>
      At Nexco.ai, we are committed to safeguarding the privacy of our users. This privacy policy explains how we collect, use, and share personal data provided by professionals using our platform, as well as the rights and choices available to users regarding their information.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      1. Introduction
    </Typography>
    <Typography paragraph>
      We collect personal data through our web platform, and additionally we may collect analytics data through third-party tools such as Mixpanel, Google Analytics etc.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      2. Use of Personal Data
    </Typography>
    <Typography paragraph>
      We use the data collected to:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>
        Create your profile, which you may then chose to share with headhunters and executive search firms with the aim of connecting you with relevant career opportunities based on your data.
      </ListItemStyled>
    </List>
    <Typography paragraph>
      We do not use personally identifiable data for any purposes beyond the consented use, unless anonymized. Anonymized data may be used for analytics, research, and other purposes without further consent from users.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      3. User Consent
    </Typography>
    <Typography paragraph>
      Consent is central to how we handle your data. Our chatbot will explicitly ask for your consent before sharing any of your personal data. You will have the opportunity to specify:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>
        Which third parties can access your data, either on a case-by-case basis, or by specifying clear rules.
      </ListItemStyled>
    </List>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      4. Data Sharing
    </Typography>
    <Typography paragraph>
      We only share your personal data with those third parties that you have approved. You have control over who can view your data and can update your preferences at any time.
    </Typography>
    <Typography paragraph>
      We do not sell or share your data with third parties for commercial purposes. However, we may use trusted third-party services to help analyze user behavior through platforms like Google Analytics and Mixpanel. Data controls are in place to ensure your data is handled securely.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      5. Data Storage and Security
    </Typography>
    <Typography paragraph>
      Your personal data is stored securely in the UK. We use strong encryption both in transit and at rest to protect your data. Access to your data is limited to select, vetted employees, and stringent access controls are in place.
    </Typography>
    <Typography paragraph>
      We use third-party services based in the EU where possible, ensuring that all data transfers comply with UK GDPR.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      6. User Rights
    </Typography>
    <Typography paragraph>
      Under the UK GDPR, you have the following rights regarding your data:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>
        <strong>Access</strong>: You can request to view the personal data we have about you.
      </ListItemStyled>
      <ListItemStyled>
        <strong>Rectification</strong>: You can ask us to correct any inaccuracies in your data.
      </ListItemStyled>
      <ListItemStyled>
        <strong>Deletion</strong>: You can request the deletion of your data.
      </ListItemStyled>
      <ListItemStyled>
        <strong>Objection</strong>: You can object to the processing of your data.
      </ListItemStyled>
      <ListItemStyled>
        <strong>Data Portability</strong>: You can request to receive your data in a machine-readable format.
      </ListItemStyled>
    </List>
    <Typography paragraph>
      To exercise any of these rights, please contact us at <a href="mailto:contact@nexco.ai">contact@nexco.ai</a>
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      7. Data Retention
    </Typography>
    <Typography paragraph>
      We will retain your personal data for as long as necessary to fulfill the purposes outlined in this policy, or as required by law. You may request deletion of your data at any time, and we will comply with your request unless legal obligations prevent us from doing so.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      8. Changes to This Privacy Policy
    </Typography>
    <Typography paragraph>
      We reserve the right to update this privacy policy as necessary. If changes are materially detrimental to your rights or how we use your data, we will notify you via email before the changes take effect.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      9. Contact Us
    </Typography>
    <Typography paragraph>
      If you have any questions about this privacy policy or how we handle your personal data, please contact our Data Protection Officer at <a href="mailto:contact@nexco.ai">contact@nexco.ai</a>
    </Typography>
  </PolicyContainer>
);

export const TermsOfService = () => (
  <PolicyContainer>
    <Typography variant="h4" component="h1" gutterBottom>
      Nexco.ai Terms of Service
    </Typography>
    
    <Typography variant="caption" display="block" gutterBottom color="text.secondary">
      Last Updated: 16/09/2024
    </Typography>

    <Typography paragraph>
      Welcome to Nexco.ai. These Terms of Service ("Terms") govern your use of Nexco.ai's website and services (collectively, the "Service"). Please read these Terms carefully before using the Service.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      1. Acceptance of Terms
    </Typography>
    <Typography paragraph>
      By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not use our Service.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      2. Description of Service
    </Typography>
    <Typography paragraph>
      Nexco.ai Ltd, registered in England, provides a platform that uses AI to collect up-to-date information about individuals. We share select summaries of this information with select headhunters, according to the individual's sharing preferences, to allow the headhunters to approach the individual about the most relevant roles. Users create profiles, log in, and share information conversationally.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      3. User Rights and Responsibilities
    </Typography>
    <Typography paragraph>
      As a user of Nexco.ai, you have the right to:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>Create a profile and share information through our chat interface</ListItemStyled>
      <ListItemStyled>Select which headhunters you would like to share your information with</ListItemStyled>
      <ListItemStyled>Access, modify, or request deletion of your information</ListItemStyled>
    </List>

    <Typography paragraph>
      Your responsibilities include:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>Providing honest and accurate information</ListItemStyled>
      <ListItemStyled>Using the Service in compliance with all applicable laws and regulations</ListItemStyled>
      <ListItemStyled>Respecting the rights of other users and third parties</ListItemStyled>
    </List>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      4. Data Collection and Usage
    </Typography>
    <Typography paragraph>
      We collect information about individuals' roles, salaries, commutes, preferences, career aspirations, and other relevant data. This information is shared with headhunters and hiring managers only with your explicit consent. For more detailed information about our data practices, please refer to our Privacy Policy.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      5. Privacy and Data Protection
    </Typography>
    <Typography paragraph>
      Nexco.ai complies with the UK General Data Protection Regulation (GDPR). We are committed to protecting your personal data and have implemented appropriate technical and organizational measures to ensure its security. For more information, please review our Privacy Policy.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      6. Intellectual Property
    </Typography>
    <Typography paragraph>
      All content and information shared on or through the Service is owned by Nexco.ai Ltd, except for information provided by users, which remains their property. By using the Service, you grant Nexco.ai a non-exclusive, worldwide, royalty-free license to use, store, and share the information you provide in accordance with these Terms and our Privacy Policy.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      7. User Accounts
    </Typography>
    <Typography paragraph>
      You may access and modify your information by logging into the platform. To delete your information, please contact contact@nexco.ai. We reserve the right to terminate your account if you provide dishonest information or attempt to misuse the Service.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      8. Limitation of Liability
    </Typography>
    <Typography paragraph>
      To the fullest extent permitted by applicable law, Nexco.ai Ltd shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
    </Typography>
    <List sx={{ pl: 4, mb: 2 }}>
      <ListItemStyled>Your use or inability to use the Service</ListItemStyled>
      <ListItemStyled>Any unauthorized access to or use of our servers and/or any personal information stored therein</ListItemStyled>
      <ListItemStyled>Any interruption or cessation of transmission to or from the Service</ListItemStyled>
      <ListItemStyled>Any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service</ListItemStyled>
    </List>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      9. Changes to Service and Terms
    </Typography>
    <Typography paragraph>
      We reserve the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. We may also modify these Terms from time to time. We will notify you of any material changes by email. Your continued use of the Service after such modifications will constitute your acknowledgment of the modified Terms and agreement to abide and be bound by them.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      10. Dispute Resolution
    </Typography>
    <Typography paragraph>
      Any disputes arising out of or relating to these Terms or the Service shall be resolved through negotiation in good faith. If the parties are unable to resolve the dispute through negotiation, the dispute shall be submitted to binding arbitration in accordance with the rules of the London Court of International Arbitration. The arbitration shall take place in London, England, and the language of the arbitration shall be English.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      11. Age Restrictions
    </Typography>
    <Typography paragraph>
      The Service is intended for use by adults only. By using the Service, you represent and warrant that you are at least 18 years of age.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      12. Governing Law
    </Typography>
    <Typography paragraph>
      These Terms shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law provisions.
    </Typography>

    <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4 }}>
      13. Contact Information
    </Typography>
    <Typography paragraph>
      If you have any questions about these Terms, please contact us at contact@nexco.ai.
    </Typography>

    <Typography paragraph>
      By using the Service, you acknowledge that you have read and understood these Terms and agree to be bound by them.
    </Typography>
  </PolicyContainer>
);