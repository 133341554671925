import { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { Profile } from '../types/profile';

const SOCKET_SERVER = process.env.REACT_APP_SOCKET_SERVER;

const socketInstances = new Map();

const getAuthToken = () => {
  try {
    return localStorage.getItem('auth_token');
  } catch (error) {
    console.error('Failed to read auth token:', error);
    return null;
  }
};

interface SocketOptions {
  requireAuthentication?: boolean;
  inviteCode?: string;
  metadata?: Record<string, string | undefined>;
}

const createSocket = (chatType: string, options: SocketOptions) => {
  const { requireAuthentication, inviteCode, metadata } = options;
  
  // Convert metadata to query string
  const query = metadata ? Object.entries(metadata)
    .filter(([_, value]) => value !== null && value !== undefined)
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value
    }), {}) : {};
  
  const newSocket = io(`${SOCKET_SERVER}/${chatType}`, {
    auth: async (cb) => {
      try {
        if (requireAuthentication) {
          const token = getAuthToken();
          cb({ type: 'jwt', token });
        } else if (inviteCode) {
          cb({ type: 'invite', code: inviteCode });
        } else {
          cb({});
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        cb(new Error('Authentication failed'));
      }
    },
    path: '/socket.io',
    transports: ['websocket'],
    query
  });

  return newSocket;
};

export const useSocket = (chatType: string, options: SocketOptions = {}) => {
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);

  // Extract values used in dependency array
  const { requireAuthentication, inviteCode, metadata } = options;
  const metadataString = JSON.stringify(metadata);

  useEffect(() => {
    // Create a unique key for this socket configuration
    const socketKey = `${chatType}-${requireAuthentication}-${inviteCode}-${metadataString}`;
    
    let currentSocket = socketInstances.get(socketKey);

    if (!currentSocket) {
      currentSocket = createSocket(chatType, options);
      socketInstances.set(socketKey, currentSocket);
    }

    setSocket(currentSocket);
    setIsConnected(currentSocket.connected);

    const handleConnect = () => setIsConnected(true);
    const handleDisconnect = () => setIsConnected(false);

    currentSocket.on('connect', handleConnect);
    currentSocket.on('disconnect', handleDisconnect);

    // Cleanup function
    return () => {
      currentSocket.off('connect', handleConnect);
      currentSocket.off('disconnect', handleDisconnect);
    };
  }, [chatType, requireAuthentication, inviteCode, metadataString, options]);

  return { socket, isConnected };
};

interface Message {
  role: string;
  content: string;
  id: number;
  waitForAnotherMessage?: boolean;
}

export const useSocketMessages = (socket) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const addUserMessage = useCallback((content) => {
    if (!socket) return;
    const userMessage: Message = {
      role: 'user',
      content,
      id: Date.now()
    };
    setIsSaving(true);
    setIsLoading(true);
    socket.emit('message', userMessage);
    setMessages(prevMessages => [...prevMessages, userMessage]);
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    const handleMessage = (data: Message | Message[]) => {
      setIsSaving(false);
      if (!('waitForAnotherMessage' in data)) {
        setIsLoading(false);
      }
      if (Array.isArray(data)) {
        setMessages(data);
      } else if (data.role) {
        setMessages(prevMessages => [...prevMessages, data]);
      }
    };
  
    socket.on('message', handleMessage);
    socket.emit("initialise")
    return () => socket.off('message', handleMessage);
  }, [socket]);

  return { messages, addUserMessage, isSaving, isLoading };
};

export const useSocketProfile = (socket) => {
  const [profile, setProfile] = useState<Profile | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!socket) return;

    socket.on('profile', (data: Profile) => {
      setProfile(data);
    });

    socket.on('profile_saving', () => {
      setIsSaving(true);
    });

    socket.on('profile_saved', () => {
      setIsSaving(false);
    });

    return () => {
      socket.off('profile');
      socket.off('profile_saving');
      socket.off('profile_saved');
    };
  }, [socket]);

  return { profile, isSaving };
};

export const useSocketProgress = (socket) => {
  const [progress, setProgress] = useState(null);

  const changeStage = useCallback((stage) => {
    if (!socket) return;
    socket.emit('stage_click', stage);
  }, [socket]);

  useEffect(() => {
    if (!socket) return;

    const handleProgress = (data) => {
      setProgress(data);
    };

    socket.on('progress', handleProgress);
    return () => socket.off('progress', handleProgress);
  }, [socket]);

  return { progress, changeStage };
};