import React from 'react';
import { Box, Typography } from '@mui/material';

export const ProfileContainer = ({ children, loading, error }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}
  >
    <Box
      sx={{
        flexGrow: 1,
        overflow: 'auto',
        width: '100%',
        maxWidth: 1200,
        mx: 'auto',
        p: 3,  // Increase padding
        boxSizing: 'border-box',
        bgcolor: 'background.default'  // Light gray background
      }}
    >
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        children
      )}
    </Box>
  </Box>
);