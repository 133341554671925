import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.ts';
import { identifyUser } from '../../analytics/identify.ts';

export const ProtectedRoute = ({ children, requiresAccount = true }) => {
  const { loading, isAuthenticated, hasValidAccount, user, hasAccount } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (user && hasAccount !== undefined) {
      identifyUser(user, hasAccount);
    }
  }, [user, hasAccount]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  if (requiresAccount && !hasValidAccount) {
    return <Navigate to="/waitlist" replace />;
  }

  return children;
};