import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useAPIWithAuth from './useAPIWithAuth.ts';
import { useAuth } from './useAuth.ts';
import set from 'lodash/set';
import get from 'lodash/get';
import { produce } from 'immer';

interface UpdateFieldParams {
  fieldKey: string;
  value: any;
}

interface DataControlUpdate {
  sharing_status: string;
  details?: string;
}

export const useProfile = (conversationType?: string) => {
  const api = useAPIWithAuth();
  const queryClient = useQueryClient();
  const { isAuthenticated, hasAccount } = useAuth();

  // Query for fetching profile
  const { 
    data: profile, 
    isLoading, 
    error 
  } = useQuery({
    queryKey: ['profile', conversationType],
    queryFn: async () => {
      const response = await api.get(
        conversationType ? `/profile/${conversationType}` : '/profile'
      );
      return response.data;
    },
    enabled: Boolean(isAuthenticated && hasAccount)
  });

  // Mutation for updating data control settings
  const dataControlMutation = useMutation({
    mutationFn: async (newDataControl: DataControlUpdate) => {
      await api.patch('/profile', {
        data_control: newDataControl
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    }
  });

  // Generic field update mutation
  const updateFieldMutation = useMutation({
    mutationFn: async ({ fieldKey, value }: UpdateFieldParams) => {
      // Create a partial update object using the field key path
      const updateData = {};
      set(updateData, fieldKey, value);

      // Send the patch request
      const response = await api.patch('/profile', updateData);
      return response.data;
    },
    onSuccess: () => {
      // Invalidate profile queries to refetch the latest data
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    }
  });

  // Helper for updating arrays (e.g., phone_numbers)
  const updateArrayFieldMutation = useMutation({
    mutationFn: async ({ fieldKey, value }: UpdateFieldParams) => {
      if (!profile) return;

      // Use immer to create an updated copy of the relevant array
      const [arrayPath, index] = fieldKey.split(/\[(\d+)\]/);
      const updatedData = produce(profile, draft => {
        const array = get(draft, arrayPath);
        if (array && Array.isArray(array)) {
          if (index) {
            // Update existing item
            array[Number(index)] = { ...array[Number(index)], ...value };
          } else {
            // Append new item
            array.push(value);
          }
        }
      });

      // Extract and send only the modified array
      const arrayUpdate = get(updatedData, arrayPath.split('.')[0]);
      const response = await api.patch('/profile', {
        [arrayPath.split('.')[0]]: arrayUpdate
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['profile'] });
    }
  });

  // Combined update field function that handles both regular and array updates
  const updateField = async ({ fieldKey, value }: UpdateFieldParams) => {
    // Check if this is an array update (contains [...])
    if (fieldKey.includes('[')) {
      return updateArrayFieldMutation.mutateAsync({ fieldKey, value });
    }
    return updateFieldMutation.mutateAsync({ fieldKey, value });
  };

  return {
    profile,
    isLoading,
    error,
    updateField,
    isUpdating: updateFieldMutation.isPending || updateArrayFieldMutation.isPending,
    updateDataControl: dataControlMutation.mutate
  };
};