import React from 'react';
import { 
  Typography,
  Box,
  Chip
} from '@mui/material';
import { 
  MapPin,
  Building2,
  Clock,
  Plane,
  Info
} from 'lucide-react';
import EmptyState from './EmptyState.tsx';
import { AdaptiveSectionLayout } from './AdaptiveSectionLayout.tsx';
import { hasNonNullValues } from '../utils/hasNonNullValues.ts';

const LocationPreferences = ({ preferences }) => {
  if (!hasNonNullValues(preferences)) {
    return (
      <EmptyState 
        icon={MapPin}
        title="No location preferences set"
        description="Share your preferences about work location, relocation options, and commute requirements"
      />
    );
  }

  const getLocationString = (location) => {
    if (!location) return null;
    const parts = [location.city, location.region, location.country].filter(Boolean);
    return parts.join(', ');
  };

  const getWorkArrangementLabel = (arrangement) => {
    const labels = {
      'FULLY_REMOTE': 'Remote',
      'HYBRID': 'Hybrid',
      'OFFICE_BASED': 'Office-based'
    };
    return labels[arrangement] || arrangement;
  };

  const sections = [
    // Current Location
    preferences.current_location && {
      icon: MapPin,
      title: "Current Location",
      content: (
        <Typography variant="body2">
          {getLocationString(preferences.current_location)}
          {preferences.current_location.details && (
            <Box component="span" color="text.secondary" sx={{ ml: 1 }}>
              ({preferences.current_location.details})
            </Box>
          )}
        </Typography>
      )
    },

    // Work Arrangements
    preferences.preferred_arrangements?.length > 0 && {
      icon: Building2,
      title: "Preferred Working Arrangements",
      content: (
        <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
          {preferences.preferred_arrangements.map((arrangement, index) => (
            <Chip
              key={index}
              label={getWorkArrangementLabel(arrangement)}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ height: 24 }}
            />
          ))}
        </Box>
      )
    },

    // Commute
    preferences.max_commute_willing && {
      icon: Clock,
      title: "Maximum Commute",
      content: (
        <Typography variant="body2">
          {preferences.max_commute_willing}
        </Typography>
      )
    },

    // Relocation
    (preferences.relocation_score !== null || preferences.relocation_rationale || preferences.relocation_factors?.length > 0) && {
      icon: Plane,
      title: "Relocation",
      content: (
        <>
          {preferences.relocation_score !== null && (
            <Typography variant="body2">
              Score: {preferences.relocation_score}/10
            </Typography>
          )}
          {preferences.relocation_rationale && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {preferences.relocation_rationale}
            </Typography>
          )}
          {preferences.relocation_factors?.length > 0 && (
            <Box sx={{ mt: 1.5 }}>
              {preferences.relocation_factors.map((factor, index) => (
                <Typography variant="body2" key={index} sx={{ mt: 0.5 }}>
                  • {factor}
                </Typography>
              ))}
            </Box>
          )}
        </>
      )
    },

    // Notes
    preferences.notes && {
      icon: Info,
      title: "Additional Notes",
      content: (
        <Typography variant="body2" color="text.secondary">
          {preferences.notes}
        </Typography>
      )
    }
  ].filter(Boolean);

  // Removed Paper wrapper and duplicate header - now managed by parent components
  return <AdaptiveSectionLayout sections={sections} />;
};

export default LocationPreferences;