import React from 'react';
import { Box, Card, Typography, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { NAV_ROUTES_ARRAY } from '../routes.ts';
import { useProfile } from '../hooks/useProfile.ts';

const QuadrantCard = ({ headline, cta, shortDescription, description, icon: Icon, color, path, isHighlighted }) => (
  <Card 
    sx={{
      p: 3,
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      '&:hover': {
        bgcolor: 'background.paper',
      },
      '& .lucide': {
        color: color
      },
      ...(isHighlighted && {
        borderLeft: '4px solid',
        borderColor: 'primary.main',
        backgroundColor: 'primary.lighter',
      })
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Icon />
      <Typography variant="h6" sx={{ ml: 2 }}>
        {headline}
      </Typography>
    </Box>
    <Typography variant="body" color="text.primary" sx={{ mb: 2 }}>
      {shortDescription}
    </Typography>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
      {description}
    </Typography>
    <Button
      component={Link}
      to={path}
      variant={isHighlighted ? "contained" : "text"}
      color="primary"
      sx={{ 
        width: "fit-content",
        mt: 'auto' 
      }}
    >
      {isHighlighted ? cta : `${cta.toUpperCase()} →`}
    </Button>
  </Card>
);

const QuadrantMenu = () => {
  const { profile } = useProfile();
  const quadrantRoutes = NAV_ROUTES_ARRAY.slice(1);

  const getRouteProps = (route) => {
    if (route.id === 'shared-profile' && profile?.onboarding_state !== "completed") {
      return {
        ...route,
        description: 'Your professional profile is incomplete. Add key information to selectively share with headhunters and internal search teams at the worlds largest companies',
        cta: "Start here now",
        isHighlighted: true,
        path: '/onboarding'
      };
    }
    return { ...route, isHighlighted: false };
  };

  return (
    <Container maxWidth="lg">
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
        gap: 2,
        p: 2
      }}>
        {quadrantRoutes.map((route) => (
          <QuadrantCard
            key={route.id}
            {...getRouteProps(route)}
          />
        ))}
      </Box>
    </Container>
  );
};

export default QuadrantMenu;