import React, { useMemo } from 'react';
import { Box, Paper } from '@mui/material';
import { ChatUI } from '../components/chat/ChatUI.tsx';
import { useSocket, useSocketMessages } from '../hooks/useSocket.ts';
import type { Message } from '../types/chat.ts';

const AIAssistant = () => {
  // Initialize socket connection for assistant chat
  const { socket, isConnected } = useSocket('assistant', {
    requireAuthentication: true
  });

  // Get messages from socket
  const { messages, addUserMessage, isLoading } = useSocketMessages(socket);

  // Track when initial messages have loaded
  const initialLoadComplete = useMemo(() => messages.length > 0, [messages.length]);

  // Process messages to ensure consistent format
  const processedMessages = useMemo(() => 
    messages.map((msg: Message, index) => ({
      id: msg.id || index,
      role: msg.role || 'user',
      content: msg.content,
    })),
    [messages]
  );

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        maxHeight: `calc(100VH - 110px)`,
        bgcolor: 'background.default',
        p: { xs: 1, sm: 2 },
        alignItems: 'center'  // Center horizontally
      }}
    >
      <Paper 
        elevation={1} 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
          maxWidth: '1200px',  // Max width
          width: '100%'  // Take full width up to max-width
        }}
      >
        <ChatUI
          messages={processedMessages}
          isConnected={isConnected}
          isLoading={isLoading}
          initialLoadComplete={initialLoadComplete}
          onSendMessage={addUserMessage}
          isMobile={false} // You could use useMediaQuery here if needed
        />
      </Paper>
    </Box>
  );
};

export default AIAssistant;