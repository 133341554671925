import React from 'react';
import { Box } from '@mui/material';
import ChatWithProgress from '../components/chat/ChatWithProgress.tsx';

const Achievements = () => {
  return (
    <Box>
        <ChatWithProgress conversationType="achievements" />
    </Box>
  );
};

export default Achievements;