import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import ContactPreferences from './sections/ContactPreferences.tsx';
import ProfessionalDNA from './sections/ProfessionalDNA.tsx';
import LocationPreferences from './sections/LocationPreferences.tsx';
import RoleArrangementsDetails from './sections/RoleArrangementsDetails.tsx';
import RoleMilestonesDetails from './sections/RoleMilestonesDetails.tsx';
import RoleRemunerationDetails from './sections/RoleRemunerationDetails.tsx';

const SECTION_TO_DATA_KEY = {
  'contact_details': 'contact_preferences',
  'professional_dna': 'professional_dna',
  'location_preferences': 'location_preferences',
  'arrangements': 'role_arrangements',
  'milestones': 'role_milestones',
  'remuneration': 'role_remuneration',
};

const SECTION_COMPONENTS = {
  'contact_details': ContactPreferences,
  'professional_dna': ProfessionalDNA,
  'location_preferences': LocationPreferences,
  'arrangements': RoleArrangementsDetails,
  'milestones': RoleMilestonesDetails,
  'remuneration': RoleRemunerationDetails,
};

interface ProfileSectionProps {
  sectionId: string;
  data: any;
  loading?: boolean;
  error?: any;
}

export const ProfileSection = ({ sectionId, data, loading = false, error }: ProfileSectionProps) => {
  const SectionComponent = SECTION_COMPONENTS[sectionId];
  const dataKey = SECTION_TO_DATA_KEY[sectionId];

  if (!SectionComponent) {
    return (
      <Box p={2}>
        <Typography>Unknown section: {sectionId}</Typography>
      </Box>
    );
  }

  // Get the correct data slice for this section
  const sectionData = dataKey && data ? data[dataKey] : data;

  return (
    <Paper elevation={0} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
      <SectionComponent details={sectionData} loading={loading} error={error} />
    </Paper>
  );
}; 