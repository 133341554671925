import React from 'react';
import { Grid, Paper, Stack } from '@mui/material';
import { ProfileContainer } from './ProfileContainer.tsx';
import { useProfile } from '../../hooks/useProfile.ts';
import { SectionHeader } from './sections/SectionHeader.tsx';

import OnboardingCTA from './sections/OnboardingCTA.tsx';
import DataControlsBanner from '../data_controls/DataControlsBanner.tsx';
import PrimaryRole from './sections/PrimaryRole.tsx';
import ExperienceList from './sections/ExperienceList.tsx';
import LocationPreferences from './sections/LocationPreferences.tsx';
import ProfessionalDNA from './sections/ProfessionalDNA.tsx';
import ContactPreferences from './sections/ContactPreferences.tsx';

const Profile = () => {
  const { profile, error, isLoading } = useProfile();

  return (
    <ProfileContainer loading={isLoading && !profile} error={error}>
      <Grid container spacing={2}>
        {profile?.onboarding_state !== "completed" && <Grid item xs={12}><OnboardingCTA /></Grid>}
        {profile?.onboarding_state === "completed" && <Grid item xs={12}><DataControlsBanner /></Grid>}
        <Grid item xs={12} md={9}>
          <Stack spacing={2}>
            <Paper
              key="Contact details"
              elevation={1}
              sx={{ 
                p: 2, 
                bgcolor: '#ffffff',
                '&:hover': {
                  '& .MuiIconButton-root': {
                    opacity: 0.7
                  }
                }
              }}
            >
              <SectionHeader 
                title="Contact details" 
                sectionId="contact_details"
              />
              <ContactPreferences details={profile?.contact_preferences} />
            </Paper>
            <Paper
              key="Insights"
              elevation={1}
              sx={{ 
                p: 2, 
                bgcolor: '#ffffff',
                '&:hover': {
                  '& .MuiIconButton-root': {
                    opacity: 0.7
                  }
                }
              }}
            >
              <SectionHeader 
                title="Insights" 
                sectionId="insights"
              />
              <ProfessionalDNA plans_and_dna={profile?.plans_and_dna} />
            </Paper>
            <PrimaryRole experiences={profile?.experiences} variant="profile" />
            <Paper
              key="Location preferences"
              elevation={1}
              sx={{ 
                p: 2, 
                bgcolor: '#ffffff',
                '&:hover': {
                  '& .MuiIconButton-root': {
                    opacity: 0.7
                  }
                }
              }}
            >
              <SectionHeader 
                title="Location preferences" 
                sectionId="location_preferences"
              />
              <LocationPreferences preferences={profile?.location_preferences} />
            </Paper>
            </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper
              key="Experience"
              elevation={1}
              sx={{ 
                p: 2, 
                bgcolor: '#ffffff'
              }}
            >
            <ExperienceList experiences={profile?.experiences} expandable={false} />
          </Paper>
        </Grid>
      </Grid>
    </ProfileContainer>
  );
};

export default Profile;