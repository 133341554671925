import React from 'react';
import { Typography, List, ListItem } from '@mui/material';
import {
  Network as OrgIcon,
  Globe as ScopeIcon,
  TrendingUp as ImpactIcon,
  User as DirectReportIcon,
  Users as IndirectReportIcon,
  ArrowUpCircle as ReportsToIcon,
  Info as ContextIcon
} from 'lucide-react';
import { AdaptiveSectionLayout, DetailRow } from './AdaptiveSectionLayout.tsx';

const RoleReporting = ({ reporting }) => {
  if (!reporting) return null;

  const content = (
    <>
      {reporting.position_context && (
        <Typography variant="body2" sx={{ mb: 2 }}>
          {reporting.position_context}
        </Typography>
      )}

      {(reporting.reporting_line || reporting.reporting_line_name) && (
        <DetailRow icon={ReportsToIcon}>
          <Typography variant="body2">
            Reports to: {reporting.reporting_line}
            {reporting.reporting_line_name && ` (${reporting.reporting_line_name})`}
          </Typography>
        </DetailRow>
      )}

      {reporting.direct_reports_details?.length > 0 && (
        <DetailRow icon={DirectReportIcon}>
          <Typography variant="body2" color="text.secondary">
            Direct Reports ({reporting.direct_reports_details.length})
          </Typography>
          <List dense disablePadding sx={{ pl: 3 }}>
            {reporting.direct_reports_details.map((report, index) => (
              <ListItem key={index} disableGutters>
                <Typography variant="body2">{report}</Typography>
              </ListItem>
            ))}
          </List>
        </DetailRow>
      )}

      {(reporting.total_number_of_indirect_reports || reporting.indirect_report_breakdown) && (
        <DetailRow icon={IndirectReportIcon}>
          <Typography variant="body2" color="text.secondary">
            Indirect Reports
            {reporting.total_number_of_indirect_reports && 
              ` (${reporting.total_number_of_indirect_reports})`}
          </Typography>
          {reporting.indirect_report_breakdown && (
            <Typography variant="body2" sx={{ mt: 0.5 }}>
              {reporting.indirect_report_breakdown}
            </Typography>
          )}
        </DetailRow>
      )}
    </>
  );

  return { icon: OrgIcon, title: "Reporting Structure", content };
};

const RoleScopeMetrics = ({ metrics }) => {
  if (!metrics?.key_scope_metrics?.length) return null;

  const content = (
    <List dense disablePadding>
      {metrics.key_scope_metrics.map((metric, index) => (
        <DetailRow key={index} icon={ScopeIcon}>
          <Typography variant="body2">
            {metric.value}
          </Typography>
          {metric.description && (
            <Typography variant="body2" color="text.secondary">
              {metric.description}
            </Typography>
          )}
        </DetailRow>
      ))}
      {metrics.additional_context && (
        <DetailRow icon={ContextIcon}>
          <Typography variant="body2" color="text.secondary">
            {metrics.additional_context}
          </Typography>
        </DetailRow>
      )}
    </List>
  );

  return { icon: ScopeIcon, title: "Scope Metrics", content };
};

const RoleImpactMetrics = ({ metrics }) => {
  if (!metrics?.key_impact_metrics?.length) return null;

  const content = (
    <List dense disablePadding>
      {metrics.key_impact_metrics.map((metric, index) => (
        <DetailRow key={index} icon={ImpactIcon}>
          <Typography variant="body2">
            {metric.value}
          </Typography>
          {metric.description && (
            <Typography variant="body2" color="text.secondary">
              {metric.description}
            </Typography>
          )}
        </DetailRow>
      ))}
    </List>
  );

  return { icon: ImpactIcon, title: "Impact Metrics", content };
};

const RoleScopeDetails = ({ scope }) => {
  if (!scope) return null;

  const sections = [
    RoleReporting({ reporting: scope.reporting }),
    RoleScopeMetrics({ metrics: scope.metrics }),
    RoleImpactMetrics({ metrics: scope.metrics })
  ].filter(Boolean);

  if (sections.length === 0) return null;

  return <AdaptiveSectionLayout sections={sections} />;
};

export default RoleScopeDetails;