import React, { memo, useRef, useEffect, useMemo, useState } from 'react';
import { List, ListItem, ListItemText, Avatar } from '@mui/material';
import { ReactComponent as AssistantIcon } from '../../assets/assistant.svg';
import { ReactComponent as LoadingIcon } from '../../assets/assistant_loading.svg';
import { useAuth } from '../../hooks/useAuth.ts';

const Message = memo(({ message, isMobile, user }) => (
  <ListItem>
    {!isMobile && (
      message.role === 'assistant' ? (
        <AssistantIcon sx={{ mr: 1 }} />
      ) : message.role === 'user' && (
        <Avatar
          src={user?.picture}
          alt={user?.name || 'User avatar'}
          sx={{ mr: 0.5 }}
          imgProps={{ referrerPolicy: "no-referrer" }}
        />
      )
    )}
    <ListItemText
      primary={
        <div style={{ whiteSpace: 'pre-wrap' }}>
          {message.content}
        </div>
      }
      sx={{
        bgcolor: message.role === 'user' ? 'background.paper' : 'background.halfway',
        padding: isMobile ? '0.5rem' : '0.5rem 1rem',
        borderRadius: '10px',
      }}
    />
  </ListItem>
));

export const MessageList = ({
  messages,
  isMobile,
  isLoading,
}) => {
  const chatEndRef = useRef(null);
  const listRef = useRef(null);
  const { user } = useAuth();
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);

  // Show loading state if explicitly loading OR if the list is empty
  const shouldShowLoading = useMemo(() =>
    isLoading || messages.length === 0,
    [isLoading, messages.length]
  );

  // Handle scroll events to determine if we should auto-scroll
  const handleScroll = () => {
    if (!listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const scrolledToBottom = scrollHeight - (scrollTop + clientHeight) < 100;
    setShouldAutoScroll(scrolledToBottom);
  };

  // Scroll to bottom only if we're near the bottom or it's a new message from the user
  useEffect(() => {
    const isNewMessageFromUser = messages.length > 0 && 
      messages[messages.length - 1].role === 'user';

    if (shouldAutoScroll || isNewMessageFromUser) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, shouldAutoScroll]);

  return (
    <List 
      ref={listRef}
      style={{ flexGrow: 1, overflowY: 'auto', padding: '1rem' }}
      onScroll={handleScroll}
    >
      {messages.map((message) => (
        <Message
          key={message.id}
          message={message}
          isMobile={isMobile}
          user={user}
        />
      ))}
      {shouldShowLoading && (
        <ListItem style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
          {!isMobile && (
            <LoadingIcon style={{ marginRight: '1rem' }} />
          )}
          <ListItemText
            primary="Loading..."
            sx={{
              color: 'text.secondary',
              '& .MuiListItemText-primary': {
                color: 'text.secondary'
              }
            }}
          />
        </ListItem>
      )}
      <div ref={chatEndRef} />
    </List>
  );
};