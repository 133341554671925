import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth.ts';
import { useProfile } from '../../hooks/useProfile.ts';

export const PublicRoute = ({ children }) => {
  const { loading, hasValidAccount, shouldWaitlist } = useAuth();
  const { profile } = useProfile();
  const location = useLocation();

  // Get the saved location from the state, defaulting to /dashboard
  const from = location.state?.from?.pathname || '/dashboard';

  // Stay in loading state until we have definitive information
  if (loading || (hasValidAccount && !profile)) {
    return <div>Loading...</div>;
  }

  // Only redirect when we have definitive account status
  if (hasValidAccount) {
    if (profile?.onboarding_state === "in_progress") {
      return <Navigate to="/onboarding" replace />;
    }
    if (profile?.onboarding_state === "data_control") {
      return <Navigate to="/onboarding-data-controls" replace />;
    }
    // Use the saved location when redirecting after login
    return <Navigate to={from} replace />;
  }

  if (shouldWaitlist) {
    return <Navigate to="/waitlist" replace />;
  }

  return children;
};