import React from 'react';
import { 
  Stack,
  Paper,
  Box,
} from '@mui/material';
import RoleScopeDetails from './RoleScopeDetails.tsx';
import RoleRemunerationDetails from './RoleRemunerationDetails.tsx';
import RoleArrangementsDetails from './RoleArrangementsDetails.tsx';
import RoleMilestonesDetails from './RoleMilestonesDetails.tsx';
import ProfessionalSatisfaction from './ProfessionalSatisfaction.tsx';
import { SectionHeader } from './SectionHeader.tsx';
import { hasNonNullValues } from '../utils/hasNonNullValues.ts';

interface Experience {
  id: string;
  title: string;
  organisation: string;
  start_date?: string;
  end_date?: string;
  is_primary?: boolean;
  remuneration_details?: any;
  scope?: any;
  arrangements_and_travel?: any;
  key_milestones?: { events: any[] };
  satisfaction_snapshots?: any[];
}

interface PrimaryRoleProps {
  experiences: Experience[];
  noPaper?: boolean;
  variant?: 'accordion' | 'profile';
}

export const SECTION_CONFIG = {
  remuneration: {
    title: 'Remuneration',
    component: RoleRemunerationDetails,
    dataKey: 'remuneration_details'
  },
  scope: {
    title: 'Scope',
    component: RoleScopeDetails,
    dataKey: 'scope'
  },
  arrangements: {
    title: 'Arrangements', 
    component: RoleArrangementsDetails,
    dataKey: 'arrangements_and_travel'
  },
  milestones: {
    title: 'Key Milestones',
    component: RoleMilestonesDetails,
    dataKey: 'key_milestones'
  },
  satisfaction: {
    title: 'Professional Satisfaction',
    component: ProfessionalSatisfaction,
    dataKey: 'satisfaction_snapshots'
  }
};

const SECTION_ORDER = {
  profile: ['remuneration', 'scope', 'arrangements', 'milestones', 'satisfaction'],
  accordion: ['satisfaction', 'scope', 'remuneration', 'milestones', 'arrangements']
};

const renderSection = (title: string, sectionId: string, content: React.ReactNode, options: { noPaper?: boolean; experience: Experience }) => {
  if (options.noPaper) {
    return (
      <Box sx={{ p: 2 }}>
        <SectionHeader 
          title={title}
          subtitle={options.experience.title + ' at ' + options.experience.organisation}
          sectionId={sectionId}
          experienceId={options.experience.id}
        />
        {content}
      </Box>
    );
  }

  return (
    <Paper 
      elevation={1} 
      sx={{ 
        p: 2, 
        bgcolor: '#ffffff',
        '&:hover': {
          '& .MuiIconButton-root': {
            opacity: 0.7
          }
        }
      }}
    >
      <SectionHeader 
        title={title}
        subtitle={options.experience.title + ' at ' + options.experience.organisation}
        sectionId={sectionId}
        experienceId={options.experience.id}
      />
      {content}
    </Paper>
  );
};

const PrimaryRole = ({ experiences = [], noPaper = false, variant = 'accordion' }: PrimaryRoleProps) => {
  const primaryRole = experiences.find(exp => exp.is_primary);
  
  if (!primaryRole) {
    return null;
  }

  const sectionOrder = SECTION_ORDER[variant];

  return (
    <Stack spacing={variant === 'profile' ? 2 : 1}>
      {sectionOrder.map(sectionId => {
        const config = SECTION_CONFIG[sectionId];
        const data = primaryRole[config.dataKey];
        
        if (hasNonNullValues(data)) {
          return renderSection(
            config.title,
            sectionId,
            <config.component {...{[config.dataKey]: data}} />,
            { noPaper, experience: primaryRole }
          );
        }
        return null;
      })}
    </Stack>
  );
};

export default PrimaryRole;