import React from 'react';
import { Typography, Stack } from '@mui/material';
import {
  DollarSign as MoneyIcon,
  Percent as BonusIcon,
  TrendingUp as EquityIcon,
  Building2 as PensionIcon,
  Gift as BenefitIcon
} from 'lucide-react';
import { AdaptiveSectionLayout } from './AdaptiveSectionLayout.tsx';

const formatCurrency = (amount, currency = 'GBP') => {
  if (!amount) return null;
  const cleanAmount = typeof amount === 'string' 
    ? parseFloat(amount.replace(/[£,]/g, ''))
    : amount;

  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(cleanAmount);
};

const Value = ({ label, value }) => {
  if (!value) return null;
  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography variant="body2" color="text.secondary">
          {label}
        </Typography>
      )}
      <Typography variant="body2">
        {value}
      </Typography>
    </Stack>
  );
};

const Salary = ({ salary_info }) => {
  if (!salary_info?.amount) return null;

  const content = (
    <Value 
      value={`${formatCurrency(salary_info.amount, salary_info.currency)}${salary_info.type === 'day_rate' ? ' per day' : ' per annum'}`}
    />
  );

  return { icon: MoneyIcon, title: "Base Salary", content };
};

const Bonus = ({ bonus }) => {
  if (!bonus || !Object.values(bonus).some(Boolean)) return null;

  const content = (
    <Stack spacing={2}>
      {bonus.target_percentage && (
        <Value value={bonus.target_percentage} />
      )}
      {bonus.typical_achievement && (
        <Value 
          label="Typical Achievement"
          value={bonus.typical_achievement}
        />
      )}
      {bonus.payment_timing && (
        <Value 
          label="Payment Schedule"
          value={bonus.payment_timing}
        />
      )}
    </Stack>
  );

  return { icon: BonusIcon, title: "Bonus", content };
};

const Equity = ({ equity }) => {
  if (!equity || !Object.values(equity).some(Boolean)) return null;

  const content = (
    <Stack spacing={2}>
      {equity.value && (
        <Value value={equity.value} />
      )}
      {equity.vesting_schedule && (
        <Value 
          label="Vesting Schedule"
          value={equity.vesting_schedule}
        />
      )}
      {equity.performance_conditions && (
        <Value 
          label="Conditions"
          value={equity.performance_conditions}
        />
      )}
    </Stack>
  );

  return { icon: EquityIcon, title: "Equity", content };
};

const Pension = ({ pension }) => {
  if (!pension || (!pension.employer_contribution && !pension.arrangement_type)) return null;

  const content = (
    <Stack spacing={2}>
      {pension.arrangement_type && (
        <Value value={pension.arrangement_type} />
      )}
      {pension.employer_contribution && (
        <Value 
          label="Employer Contribution"
          value={pension.employer_contribution}
        />
      )}
    </Stack>
  );

  return { icon: PensionIcon, title: "Pension", content };
};

const Benefits = ({ benefits }) => {
  if (!benefits?.length) return null;

  const content = (
    <Stack spacing={2}>
      {benefits.map((benefit, index) => (
        benefit.type && (
          <Value 
            key={index}
            value={benefit.value || benefit.description}
          />
        )
      ))}
    </Stack>
  );

  return { icon: BenefitIcon, title: "Benefits", content };
};

const RoleRemunerationDetails = ({ remuneration_details }) => {
  if (!remuneration_details) return null;

  const sections = [
    Salary({ salary_info: remuneration_details.salary_info }),
    Bonus({ bonus: remuneration_details.bonus }),
    Equity({ equity: remuneration_details.equity }),
    Pension({ pension: remuneration_details.pension }),
    Benefits({ benefits: remuneration_details.additional_benefits })
  ].filter(Boolean);

  if (sections.length === 0) return null;

  return <AdaptiveSectionLayout sections={sections} />;
};

export default RoleRemunerationDetails;