import React from 'react';
import { Typography, Box, Chip } from '@mui/material';
import {
  CalendarCheck as ReviewIcon,
  Target as GoalIcon,
  TrendingUp as PerformanceIcon,
  DollarSign as BonusIcon,
  Calendar as DateIcon,
  RotateCcw as RecurringIcon,
  FileText as NotesIcon
} from 'lucide-react';
import { AdaptiveSectionLayout } from './AdaptiveSectionLayout.tsx';

const EVENT_TYPES = {
  'Performance Review': { icon: PerformanceIcon, color: '#4CAF50' },
  'Goal Setting': { icon: GoalIcon, color: '#2196F3' },
  'Bonus Payment': { icon: BonusIcon, color: '#FF9800' },
  'Review': { icon: ReviewIcon, color: '#9C27B0' }
};

const MilestoneEventItem = ({ event }) => {
  return (
    <Box sx={{ mb: 2, '&:last-child': { mb: 0 } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap', mb: 0.5 }}>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {event.event_type}
        </Typography>
        {event.cadence && (
          <Chip
            icon={<RecurringIcon size={14} />}
            label={event.cadence}
            size="small"
            variant="outlined"
            sx={{ height: 20 }}
          />
        )}
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: 'text.secondary' }}>
        <DateIcon size={14} />
        <Typography variant="body2">
          {event.date}
        </Typography>
      </Box>
      
      {event.notes && (
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-start', 
          gap: 1, 
          mt: 0.5,
          color: 'text.secondary' 
        }}>
          <NotesIcon size={14} style={{ marginTop: '3px' }} />
          <Typography variant="body2">
            {event.notes}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const RoleMilestonesDetails = ({ key_milestones }) => {
  if (!key_milestones?.events?.length) return null;

  // Group events by type
  const groupedEvents = key_milestones.events.reduce((acc, event) => {
    const type = event.event_type;
    if (!acc[type]) acc[type] = [];
    acc[type].push(event);
    return acc;
  }, {});

  // Create sections for each event type
  const sections = Object.entries(groupedEvents).map(([type, events]) => {
    const eventConfig = EVENT_TYPES[type] || EVENT_TYPES['Review'];
    
    return {
      icon: eventConfig.icon,
      title: type + 's', // Pluralize the section title
      content: (
        <Box>
          {events.map((event, index) => (
            <MilestoneEventItem
              key={`${type}-${index}`}
              event={event}
            />
          ))}
        </Box>
      )
    };
  });

  return <AdaptiveSectionLayout sections={sections} />;
};

export default RoleMilestonesDetails;