import React, { useEffect } from 'react';
import { Progress } from './Progress.tsx';

interface ProgressTrackerProps {
  progress: Record<string, string> | null;
  isConnected: boolean;
  changeStage: (stage: string) => void;
  onChatCompleted: () => void;
}

const ProgressTracker: React.FC<ProgressTrackerProps> = ({ 
  progress, 
  isConnected, 
  changeStage, 
  onChatCompleted 
}) => {

  useEffect(() => {
    if (progress) {
      const progressValues = Object.values(progress);
      if (progressValues.length > 0 && progressValues.every(value => value === "completed")) {
        onChatCompleted();
      }
    }
  }, [progress, onChatCompleted]);

  const handleStageClick = (stage: string) => {
    if (!isConnected) return;
    
    changeStage(stage);
  };

  if (!progress) {
    return <div className="text-blue-600 bg-blue-50 p-3 rounded">Loading progress...</div>;
  }

  return (
    <Progress 
      summary={Object.entries(progress)}
      onStageClick={handleStageClick}
      disabled={!isConnected}
    />
  );
};

export default ProgressTracker;