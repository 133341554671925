import React from 'react';
import { 
  Paper,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { NAV_ROUTES_ARRAY, MOBILE_NAV_ROUTES_ARRAY } from '../routes.ts';
import AccountMenu from './AccountMenu.tsx';

const NavigationMenu = () => {
  const [, setValue] = React.useState('dashboard');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();

  if (isMobile) {
    return (
      <Paper 
        sx={{ 
          position: 'fixed', 
          bottom: 0, 
          left: 0, 
          right: 0,
          borderRadius: 0,
          backgroundColor: 'white'
        }} 
        elevation={8}
      >
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            height: 64,
            px: 1,
            pt: 1
          }}
        >
          {MOBILE_NAV_ROUTES_ARRAY.map(({ id, path, shortLabel, icon: Icon, color }) => (
            <Link
              key={id}
              to={path}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box
                onClick={() => setValue(id)}
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: 0.5,
                  px: 1,
                  cursor: 'pointer',
                  color: location.pathname === path ? 'primary.main' : 'text.secondary',
                  '& .lucide': {
                    strokeWidth: location.pathname === path ? 2.5 : 1.5,
                    transition: 'all 0.2s ease-in-out',
                    color: color
                  },
                  transition: 'all 0.2s ease-in-out',
                }}
              >
                <Icon size={24}/>
                <Box 
                  component="span" 
                  sx={{ 
                    fontWeight: location.pathname === path ? 600 : 500,
                    fontSize: '0.75rem',
                    lineHeight: '1',
                  }}
                >
                  {shortLabel}
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
      </Paper>
    );
  }

  // Desktop version
  return (
    <Paper 
      sx={{ 
        width: '100%',
        borderRadius: 0,
      }} 
      elevation={0}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          height: 60,
          px: 2,
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            flexGrow: 1,
            gap: 1,
            height: '100%',
          }}
        >
          {NAV_ROUTES_ARRAY.map(({ id, path, label, icon: Icon, color }) => (
            <Link
              key={id}
              to={path}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <Box
                onClick={() => setValue(id)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  px: 2,
                  py: 2,
                  cursor: 'pointer',
                  transition: 'all 0.2s ease-in-out',
                  color: location.pathname === path ? 'primary.main' : 'text.secondary',
                  backgroundColor: location.pathname === path ? 'primary.lighter' : 'transparent',
                  '&:hover': {
                    backgroundColor: location.pathname === path ? 'primary.lighter' : 'action.hover',
                  },
                  '& .lucide': {
                    strokeWidth: location.pathname === path ? 2.5 : 1.5,
                    transition: 'all 0.2s ease-in-out',
                    color: color
                  },
                }}
              >
                <Icon height={18}/>
                <Box>
                  <Box 
                    component="span" 
                    sx={{ 
                      fontWeight: location.pathname === path ? 600 : 500,
                      fontSize: '0.95rem',
                      lineHeight: '1.2',
                      display: 'block',
                    }}
                  >
                    {label}
                  </Box>
                </Box>
              </Box>
            </Link>
          ))}
        </Box>
        <AccountMenu variant="topnav" />
      </Box>
    </Paper>
  );
};

export default NavigationMenu;