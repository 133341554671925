import React, { createContext, useState, useEffect, useCallback, useRef, ReactNode } from 'react';
import { Auth0Lock } from 'auth0-lock';
import useAPIWithAuth from '../hooks/useAPIWithAuth.ts';
import authConfig from '../auth_config.json';
const { domain, clientId } = authConfig;

interface AuthContextType {
  isAuthenticated: boolean;
  user: any | null;
  accessToken: string | null;
  error: string | null;
  loading: boolean;
  login: (authResult: any) => Promise<void>;
  logout: () => void;
  hasAccount: boolean | null;
  hasValidAccount: boolean | null;
  shouldWaitlist: boolean | null;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  user: null,
  accessToken: null,
  error: null,
  loading: true,
  login: async () => {},
  logout: () => {},
  hasAccount: null,
  hasValidAccount: null,
  shouldWaitlist: null
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<any | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasAccount, setHasAccount] = useState<boolean | null>(null);
  const [hasValidAccount, setHasValidAccount] = useState<boolean | null>(null);
  const [shouldWaitlist, setShouldWaitlist] = useState<boolean | null>(null);
  const refreshTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  
  const api = useAPIWithAuth();

  const clearAuthData = useCallback(() => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('user_info');
    localStorage.removeItem('expires_at');
    setAccessToken(null);
    setUser(null);
    setIsAuthenticated(false);
    setError(null);
    setHasAccount(null);
    setHasValidAccount(null);
    setShouldWaitlist(null);
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
  }, []);

  const logout = useCallback(() => {
    clearAuthData();
    window.location.replace(
      `https://${domain}/v2/logout?client_id=${clientId}&returnTo=${encodeURIComponent(window.location.origin)}`
    );
  }, [clearAuthData]);

  const updateProfileAndWaitlistStatus = useCallback(async (userInfo) => {
    const response = await api.get('/waitlist/');
    let result = (response.status === 200) ? "ready" : "waiting";
    setHasAccount(!!result);
    setHasValidAccount(result === "ready");
    setShouldWaitlist(result !== "ready");
    await api.put('/profile/', {
      email: userInfo.email,
      given_name: userInfo.given_name,
      family_name: userInfo.family_name,
      picture: userInfo.picture
    });
  }, [api]);

  const handleAuthResult = useCallback(async (authResult, shouldUpdateProfile = true) => {
    const { accessToken, idTokenPayload, expiresIn } = authResult;
    
    // Store in localStorage
    localStorage.setItem('auth_token', accessToken);
    localStorage.setItem('user_info', JSON.stringify(idTokenPayload));
    localStorage.setItem('expires_at', JSON.stringify(expiresIn * 1000 + new Date().getTime()));
    
    // Update context state
    setAccessToken(accessToken);
    setUser(idTokenPayload);
    setIsAuthenticated(true);
    setError(null);

    // Optionally update profile
    if (shouldUpdateProfile) {
      await updateProfileAndWaitlistStatus(idTokenPayload);
    }
  }, [updateProfileAndWaitlistStatus]);

  const scheduleTokenRefresh = useCallback(() => {
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current);
    }
    const refreshDelay = 600000;
    console.log(refreshDelay/1000);
    if (refreshDelay > 0) {
      refreshTimeoutRef.current = setTimeout(async () => {
        console.log('refreshing')
        
        const lock = new Auth0Lock(clientId, domain);
        lock.checkSession({
          nonce: Math.random().toString(36).substring(2),
          auth:{
          redirect_uri: window.location.origin
          } 
        }, (error, authResult) => {
        })
      }, refreshDelay);
    }
  }, []);

  const login = useCallback(async (authResult) => {
    try {
      await handleAuthResult(authResult, true);
      await scheduleTokenRefresh();
      setLoading(false);
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message);
      clearAuthData();
    }
  }, [handleAuthResult, clearAuthData, scheduleTokenRefresh]);

  useEffect(() => {
    const checkAuthSession = async () => {
      const token = localStorage.getItem('auth_token');
      const userInfoStr = localStorage.getItem('user_info');
      const expiresAt = localStorage.getItem('expires_at');
      
      if (token && userInfoStr && expiresAt) {
        const expiresAtMs = JSON.parse(expiresAt);
        const now = new Date().getTime();
        
        if (now < expiresAtMs) {
          const userInfo = JSON.parse(userInfoStr);
          setAccessToken(token);
          setUser(userInfo);
          setIsAuthenticated(true);
          
          // Schedule refresh for remaining time
          const remainingTime = (expiresAtMs - now) / 1000;
          scheduleTokenRefresh(remainingTime);
          await updateProfileAndWaitlistStatus(userInfo);
        } else {
          clearAuthData();
        }
      }
      
      setLoading(false);
    };

    checkAuthSession();
    
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current);
      }
    };
  }, [updateProfileAndWaitlistStatus, clearAuthData, scheduleTokenRefresh]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        accessToken,
        error,
        loading,
        login,
        logout,
        hasAccount,
        hasValidAccount,
        shouldWaitlist
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};