import React, { useEffect } from 'react';
import { Box, LinearProgress, Typography, useTheme, useMediaQuery } from '@mui/material';

const LinearProgressWithLabel = ({ progress, onChatCompleted }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const completedStages = Object.values(progress || {}).filter(status => status === 'completed').length;
  const totalStages = Object.values(progress || {}).length;
  const percentage = totalStages ? (completedStages / totalStages) * 100 : 0;

  useEffect(() => {
    if (progress && Object.values(progress).every(value => value === "completed")) {
      onChatCompleted();
    }
  }, [progress, onChatCompleted]);

  return (
    <Box sx={{ 
      width: '100%',
      zIndex: 1099,
      bgcolor: { xs: 'white', md: 'background.paper' },
      borderColor: 'grey.400',
      borderTop: 1,
      borderBottom: 1,
      p: 2
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <LinearProgress 
          variant="determinate" 
          value={percentage} 
          sx={{
            flexGrow: 1,
            height: 8,
            borderRadius: 4
          }}
        />
        <Typography variant="body2" color="text.secondary" component="div" sx={{ display: 'flex', gap: 0.5 }}>
          {`${completedStages}/${totalStages}`}
          {isDesktop && ' stages completed'}
        </Typography>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;