import React from 'react';
import { Container, Grid } from '@mui/material';
import ExecutiveProfileHeader from './ExecutiveProfileHeader.tsx';
import ContactDetails from './ContactDetails.tsx';
import CareerAspirations from './CareerAspirations.tsx';
import ReadinessForChange from './ReadinessForChange.tsx';
import CompensationBenefits from './CompensationBenefits.tsx';
import RoleSatisfaction from './RoleSatisfaction.tsx';
import Experience from './Experience.tsx';
import PerformanceAchievements from './PerformanceAchievements.tsx';
import Education from './Education.tsx';
import WorkArrangement from './WorkArrangement.tsx';
import FamilyAndInterests from './FamilyAndInterests.tsx';
import QuickFacts from './QuickFacts.tsx';

const ExecutiveProfile = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const valid = urlParams.get('key') === 'qyntoh-qoqfiw-bupNo2';
  if (!valid) {
    return null;
  }
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <ExecutiveProfileHeader />
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <ContactDetails />
          <ReadinessForChange />
          <QuickFacts />
          <CompensationBenefits />
          <WorkArrangement />
          <FamilyAndInterests />
          <RoleSatisfaction />
          <CareerAspirations />
          <PerformanceAchievements />
        </Grid>
        <Grid item xs={12} md={4}>
          <Experience />
          <Education />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ExecutiveProfile;