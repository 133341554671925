import { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, CircularProgress, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

export const ChatInput = ({
  onSendMessage,
  isLoading,
  disabled = false,
  initialLoadComplete = false
}) => {
  const [input, setInput] = useState('');
  const inputRef = useRef(null);
  const hasLoadedRef = useRef(false);

  useEffect(() => {
    if (initialLoadComplete && !hasLoadedRef.current) {
      hasLoadedRef.current = true;
      inputRef.current?.focus();
    }
  }, [initialLoadComplete]);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  const handleSend = async () => {
    if (input.trim() === '') return;
    onSendMessage(input);
    setInput('');
  };

  const endAdornment = input.trim() !== '' && (
    <InputAdornment position="end">
      <IconButton 
        onClick={handleSend}
        disabled={isLoading || disabled}
        edge="end"
        size="small"
        color="primary"
        sx={{
          color: 'common.white',
          bgcolor: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.dark',
          },
          '&:disabled': {
            bgcolor: 'primary.main',
            opacity: 0.7,
          },
          padding: '8px',
          marginRight: '8px',
        }}
      >
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <SendIcon fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );

  return (
    <div style={{ padding: '1rem' }}>
      <TextField
        fullWidth
        variant="outlined"
        multiline
        maxRows={4}
        label="Type a message"
        value={input}
        inputRef={inputRef}
        autoFocus
        readOnly={isLoading}
        disabled={disabled}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          endAdornment: endAdornment
        }}
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            transform: "translateZ(0)"
          }
        }}
      />
    </div>
  );
};