// Revised
import React, { useState, useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../sidebar/Sidebar.tsx';
import ChatAndArtifactsContainer from './ChatAndArtifactsContainer.tsx';
import { ChatContainer } from './ChatContainer.tsx';
import ProfileAccordion from '../profile/ProfileAccordion.tsx';
import ProgressTracker from './ProgressTracker.tsx';
import { useSocket, useSocketMessages, useSocketProfile, useSocketProgress } from '../../hooks/useSocket.ts';

const ChatWithProgress = ({conversationType}) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const { socket, isConnected } = useSocket(conversationType, {
    requireAuthentication: true
  });

  const { messages, addUserMessage, isSaving, isLoading } = useSocketMessages(socket);
  const { profile } = useSocketProfile(socket);
  const { progress, changeStage } = useSocketProgress(socket);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prev) => !prev);
  }, []);
  

  // Stable data references
  const chatData = useMemo(() => ({ messages, isConnected, addUserMessage, isLoading }), [messages, isConnected, addUserMessage, isLoading]);
  const profileData = useMemo(() => ({ profile, changeStage, isSaving }), [profile, changeStage, isSaving]);

  const progressContent = useMemo(() =>{
    const onChatCompleted = () => {
      if(conversationType === "onboarding"){
        navigate('/onboarding-data-controls');
      }
      else if (conversationType === "achievements"){
        // navigate('/shared-profile');
      }
    }
    return (
    <ProgressTracker progress={progress} isConnected={isConnected} changeStage={changeStage} onChatCompleted={onChatCompleted} />
  )}, [progress, isConnected, changeStage, conversationType, navigate]);

  // Define these outside or as stable references to avoid inline recreations:
  const RenderChatContainer = useCallback((props) => <ChatContainer {...props} />, []);
  const RenderProfileAccordion = useCallback((props) => <ProfileAccordion {...props} />, []);

  return (
    <Box sx={{ display: 'flex', flex: 1}}>
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        content={progressContent}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          overflow: 'hidden'
        }}
      >
        <ChatAndArtifactsContainer
          chatData={chatData}
          profileData={profileData}
          ChatComponent={RenderChatContainer}
          ProfileComponent={RenderProfileAccordion}
        />
      </Box>
    </Box>
  );
};

export default ChatWithProgress;
