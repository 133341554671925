import { useMemo } from 'react';
import PrimaryRole from './sections/PrimaryRole.tsx';
import ExperienceList from './sections/ExperienceList.tsx';
import LocationPreferences from './sections/LocationPreferences.tsx';
import ProfessionalDNA from './sections/ProfessionalDNA.tsx';
import ContactPreferences from './sections/ContactPreferences.tsx';

const PROFILE_SECTIONS = [
  {
    component: ExperienceList,
    propKey: 'experiences',
    dataKey: 'experiences',
    title: "Your experience",
    stage: 'none',
  },
  {
    component: PrimaryRole,
    propKey: 'experiences',
    dataKey: 'experiences',
    title: "Primary role",
    stage: 'employment',
  },
  {
    component: ProfessionalDNA,
    propKey: 'plans_and_dna',
    dataKey: 'plans_and_dna',
    title: "Plans and professional DNA",
    stage: 'plans and professional DNA',
  },
  {
    component: LocationPreferences,
    propKey: 'preferences',
    dataKey: 'location_preferences',
    title: "Location Preferences",
    stage: 'location preferences',
  },
  {
    component: ContactPreferences,
    propKey: 'details',
    dataKey: 'contact_preferences',
    title: "Contact Preferences",
    stage: 'contact preferences',
  }
];


const createSection = (sectionConfig, profileData) => ({
  ...sectionConfig,
  props: { [sectionConfig.propKey]: profileData[sectionConfig.dataKey] }
});

export const useProfileSections = (profile) => {
  return useMemo(() => {
    if (!profile) return [];

    return PROFILE_SECTIONS
      .map(section => createSection(section, profile));
  }, [profile]);
};