import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Edit as EditIcon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface SectionHeaderProps {
  title: string;
  sectionId: string;
  experienceId?: string;  // Optional for non-experience sections
  subtitle?: string;  // Optional subtitle
}

export const SectionHeader = ({ title, sectionId, experienceId, subtitle }: SectionHeaderProps) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    const editPath = experienceId 
      ? `/shared-profile/edit/${sectionId}/${experienceId}`
      : `/shared-profile/edit/${sectionId}`;
    navigate(editPath);
  };

  return (
    <Box sx={{
      containerType: 'inline-size',
      width: '100%',
      mb: 2,
      display: 'flex',
      justifyContent: 'space-between'
     }}>
      <Box>
        <Typography variant="h6" sx={{ 
          display: 'flex', 
          alignItems: 'baseline', 
          gap: 2,
          '@container (max-width: 399px)': {
            flexDirection: 'column',
            gap: 0.5
          }
        }}>
          {title}
          {subtitle && (
            <Typography variant="body2" component="span" color="text.secondary">
              {subtitle}
            </Typography>
          )}
        </Typography>
      </Box>
      <IconButton 
        size="small" 
        onClick={handleEdit}
        sx={{ 
          opacity: 0,
          transition: 'opacity 0.2s',
          '&:hover': { opacity: 1 }
        }}
      >
        <EditIcon size={16} />
      </IconButton>
    </Box>
  );
}; 