import React from 'react';
import { MessageList } from './MessageList.tsx';
import { ChatInput } from './ChatInput.tsx';
import { ConnectionStatus } from './ConnectionStatus.tsx';

export const ChatUI = ({
  messages,
  isConnected,
  isLoading,
  initialLoadComplete,
  onSendMessage,
  isMobile
}) => {
  return (
    <>
      <ConnectionStatus isConnected={isConnected} />
      
      <MessageList 
        messages={messages}
        isMobile={isMobile}
        isLoading={isLoading}
      />
      
      <ChatInput 
        onSendMessage={onSendMessage}
        isLoading={isLoading}
        initialLoadComplete={initialLoadComplete}
      />
    </>
  );
};