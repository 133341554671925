import React, { useState, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { CssBaseline, Box, Button, Divider, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Cookies from 'js-cookie';
import Sidebar from '../sidebar/Sidebar.tsx';
import ChatContainer from '../chat/ChatContainer.tsx';
import { useSocket, useSocketMessages } from '../../hooks/useSocket.ts';

const Introduction = () => {
  const { loginWithRedirect } = useAuth0();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;
  
  const inviteCode = Cookies.get('invite_code');
  
  // Get connection status and sendMessage from base hook
  const { socket, isConnected } = useSocket("introduction", {
    requireAuthentication: false,
    inviteCode: inviteCode
  });
  
  const { messages, addUserMessage } = useSocketMessages(socket);

  // Memoize the chat data
  const chatData = useMemo(() => ({
    messages,
    isConnected,
    addUserMessage
  }), [messages, isConnected, addUserMessage]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const CustomChatContainer = () => (
    <>
      <ChatContainer data={chatData} requireAuthentication={false} />
      <Divider sx={{ m: 2 }}>or</Divider>
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 1, ml: 2 }}
        onClick={() => loginWithRedirect({
          redirect_uri: '/dashboard'
        })}
      >
        I'm happy, let's go!
      </Button>
    </>
  );

  const mainBoxSx = {
    flexGrow: 1,
    width: {
      xs: '100%',
      sm: drawerWidth ? `calc(100% - ${drawerWidth}px)` : '100%'
    },
    marginLeft: {
      xs: 0,
      sm: drawerWidth ? `${drawerWidth}px` : 0
    },
    height: '100vh',
    overflow: 'hidden',
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <CssBaseline />
      <Sidebar
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        isMobile={isMobile}
        drawerWidth={drawerWidth}
        content={() => null}
      />
      <Box
        component="main"
        sx={mainBoxSx}
      >
        {inviteCode && <CustomChatContainer />}
      </Box>
    </Box>
  );
};

export default Introduction;