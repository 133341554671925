import React from 'react';
import { Box, Typography, Stack, styled } from '@mui/material';

const LayoutContainer = styled(Box)({
  containerType: 'inline-size',
  width: '100%'
});

const ResponsiveSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  '@container (max-width: 399px)': {
    flexDirection: 'column',
    gap: theme.spacing(1)
  }
}));

const SectionContent = styled(Box)(({ theme }) => ({
  flex: 1,
  '@container (max-width: 399px)': {
    paddingLeft: theme.spacing(4)
  },
  '@container (min-width: 400px)': {
    paddingLeft: theme.spacing(3)
  }
}));

const LabelWithIcon = ({ icon: Icon, title }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center', 
    gap: 1,
    '@container (min-width: 400px)': {
      minWidth: '180px'
    }
  }}>
    <Icon size={18} style={{ color: 'rgba(0, 0, 0, 0.6)' }} />
    <Typography variant="body2" color="text.secondary">
      {title}
    </Typography>
  </Box>
);

const DetailRow = ({ icon: Icon, children }) => (
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start' }}>
    {Icon && <Icon size={16} style={{ marginTop: 4 }} />}
    <Box sx={{ flex: 1 }}>{children}</Box>
  </Box>
);

const AdaptiveSectionLayout = ({ sections }) => (
  <LayoutContainer>
    <Stack spacing={2}>
      {sections.map((section, index) => (
        <ResponsiveSection key={index}>
          <LabelWithIcon icon={section.icon} title={section.title} />
          <SectionContent>
            <Stack spacing={1}>
              {section.content}
            </Stack>
          </SectionContent>
        </ResponsiveSection>
      ))}
    </Stack>
  </LayoutContainer>
);

export {
  LayoutContainer,
  ResponsiveSection,
  SectionContent,
  LabelWithIcon,
  DetailRow,
  AdaptiveSectionLayout
};