import React from 'react';
import { 
  Typography,
  Box,
  styled
} from '@mui/material';
import { 
  Phone,
  Mail,
  Star,
  Info,
  RotateCcw
} from 'lucide-react';
import EmptyState from './EmptyState.tsx';
import { hasNonNullValues } from '../utils/hasNonNullValues.ts';

// Container query wrapper
const ContainerQueryWrapper = styled(Box)({
  containerType: 'inline-size',
  width: '100%'
});

const ContactGrid = styled(Box)(({ theme }) => ({
  '@container (min-width: 600px)': {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2)
  }
}));

const ContactSection = ({ icon: Icon, title, value }) => {
  if (!value) return null;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1.5, py: 1 }}>
      <Icon size={18} style={{ color: 'rgba(0, 0, 0, 0.6)', marginTop: '3px' }} />
      <Box>
        <Typography variant="body2" color="text.secondary">
          {title}
        </Typography>
        <Typography variant="body2">
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

const ContactPreferences = ({ details }) => {
  if (!hasNonNullValues(details)) {
    return (
      <EmptyState 
        icon={Phone}
        title="No contact preferences set"
        description="Add your preferred contact methods and when you'd like to be reached"
      />
    );
  }

  const { phone_numbers, email_addresses, contact_preference } = details;

  // Removed Paper wrapper - now managed by parent components
  return (
    <ContainerQueryWrapper>
      <ContactGrid>
        {/* Phone Numbers Column */}
        <Box>
          {phone_numbers?.map((phone, index) => (
            <ContactSection
              key={index}
              icon={Phone}
              title={phone.label}
              value={phone.number}
            />
          ))}
        </Box>

        {/* Email Addresses Column */}
        <Box>
          {email_addresses?.map((email, index) => (
            <ContactSection
              key={index}
              icon={Mail}
              title={email.label}
              value={email.email}
            />
          ))}
        </Box>

        {/* Primary Method - Start new row in grid */}
        {contact_preference?.primary_method && (
          <ContactSection
            icon={Star}
            title="Primary Method"
            value={contact_preference.primary_method}
          />
        )}

        {/* Secondary Method */}
        {contact_preference?.follow_up_method && (
          <ContactSection
            icon={RotateCcw}
            title="Secondary Method"
            value={contact_preference.follow_up_method}
          />
        )}

        {/* Notes - Full Width */}
        {contact_preference?.additional_notes && (
          <Box sx={{ 
            gridColumn: '1 / -1',
            '@container (max-width: 599px)': {
              gridColumn: 'auto'
            }
          }}>
            <ContactSection
              icon={Info}
              title="Additional Notes"
              value={contact_preference.additional_notes}
            />
          </Box>
        )}
      </ContactGrid>
    </ContainerQueryWrapper>
  );
};

export default ContactPreferences;