import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Alert, Snackbar, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { ChevronUp as ChevronUpIcon } from 'lucide-react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';

interface ChatLayoutProps {
  children: React.ReactNode;
  error: string | null;
  onErrorClose: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

interface ChatSectionProps {
  children: React.ReactNode;
  isMobile: boolean;
  isSheetOpen: boolean;
}

interface SidebarSectionProps {
  children: React.ReactNode;
  isMobile: boolean;
}

interface FloatingButtonProps {
  onClick: () => void;
}

interface ChatAndArtifactsContainerProps {
  chatData: any;
  profileData: any;
  ChatComponent: React.ComponentType<any>;
  ProfileComponent?: React.ComponentType<any> | null;
  initialError?: string | null;
}

const ChatLayout: React.FC<ChatLayoutProps> = React.memo(({ children, error, onErrorClose }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
    }}
  >
    <div
      style={{
        flex: 1,
        minHeight: 0,
        display: 'flex',
        gap: '24px',
      }}
    >
      {children}
    </div>
    <Snackbar open={!!error} autoHideDuration={6000} onClose={onErrorClose}>
      <Alert onClose={onErrorClose} severity="error" sx={{ width: '100%' }}>
        {error}
      </Alert>
    </Snackbar>
  </div>
));

const ChatSection: React.FC<ChatSectionProps> = React.memo(({ children, isMobile, isSheetOpen }) => {
  const chatSectionStyle = useMemo(
    () => ({
      flex: isMobile ? 1 : '0 0 66.666%',
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: isMobile && isSheetOpen ? '100px' : 0,
      maxHeight: `calc(100VH - 110px)`,
      overflowY: 'auto'
    }),
    [isMobile, isSheetOpen]
  );

  return <div style={chatSectionStyle}>{children}</div>;
});

const SidebarSection: React.FC<SidebarSectionProps> = React.memo(({ children, isMobile }) => {
  if (!children || isMobile) return null;

  return (
    <div
      style={{
        flex: '0 0 33.333%',
        minHeight: 0,
        maxHeight: `calc(100VH - 110px)`,
        overflowY: 'auto'
      }}
    >
      {children}
    </div>
  );
});

const FloatingButton: React.FC<FloatingButtonProps> = React.memo(({ onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      position: 'fixed',
      bottom: 16,
      right: 16,
      backgroundColor: 'primary.main',
      color: 'white',
      '&:hover': {
        backgroundColor: 'primary.dark',
      },
      width: 48,
      height: 48,
      boxShadow: 2,
    }}
  >
    <ChevronUpIcon />
  </IconButton>
));

const ChatAndArtifactsContainer: React.FC<ChatAndArtifactsContainerProps> = ({
  chatData,
  profileData,
  ChatComponent,
  ProfileComponent = null,
  initialError = null
}) => {
  const [containerError, setContainerError] = useState(initialError);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleErrorClose = useCallback((event, reason) => {
    if (reason === 'clickaway') return;
    setContainerError(null);
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setIsSheetOpen(false);
    }
  }, [isMobile]);

  const handleFloatingButtonClick = useCallback(() => {
    setIsSheetOpen(true);
  }, []);

  const handleSheetDismiss = useCallback(() => {
    setIsSheetOpen(false);
  }, []);

  const snapPoints = useMemo(
    () => ({ maxHeight }) => [
      maxHeight * 0.25,
      maxHeight * 0.5,
      maxHeight * 0.9
    ],
    []
  );

  return (
    <ChatLayout error={containerError} onErrorClose={handleErrorClose}>
      <ChatSection isMobile={isMobile} isSheetOpen={isSheetOpen}>
        {/* Pass chatData to ChatComponent */}
        <ChatComponent data={chatData} onError={setContainerError} />
      </ChatSection>
      <SidebarSection isMobile={isMobile}>
        {ProfileComponent && <ProfileComponent data={profileData} />}
      </SidebarSection>

      {isMobile && ProfileComponent && (
        <>
          <FloatingButton onClick={handleFloatingButtonClick} />

          <BottomSheet
            open={isSheetOpen}
            onDismiss={handleSheetDismiss}
            snapPoints={snapPoints}
            defaultSnap={({ snapPoints }) => snapPoints[1]}
            expandOnContentDrag
            header={
              <div style={{ width: '100%', height: '6px', padding: '10px' }}>
                <div
                  style={{
                    width: '48px',
                    height: '4px',
                    backgroundColor: '#E0E0E0',
                    borderRadius: '2px',
                    margin: '0 auto',
                  }}
                />
              </div>
            }
          >
            <div style={{ padding: '20px' }}>
              <ProfileComponent data={profileData} />
            </div>
          </BottomSheet>
        </>
      )}

    </ChatLayout>
  );
};

export default React.memo(ChatAndArtifactsContainer);
