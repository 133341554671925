import React, { useState, useEffect, useMemo } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import { ChatUI } from './ChatUI.tsx';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export const ChatContainer = ({ data }) => {
  const { messages, isConnected, addUserMessage, isLoading } = data;
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (messages.length > 0) {
      setInitialLoadComplete(true);
    }
  }, [messages]);


  const processedMessages = useMemo(
    () =>
      messages.map((msg, index) => ({
        id: msg.id || index,
        role: msg.role || 'user',
        content: msg.content,
      })),
    [messages]
  );

  return (
    <ChatUI
      messages={processedMessages}
      isConnected={isConnected}
      isLoading={isLoading}
      initialLoadComplete={initialLoadComplete}
      onSendMessage={addUserMessage}
      isMobile={isMobile}
    />
  );
};

// Wrapper to handle authentication if required
const ChatContainerWrapper = ({ requireAuthentication, ...props }) => {
  const Component = requireAuthentication
    ? withAuthenticationRequired(ChatContainer)
    : ChatContainer;
  return <Component {...props} />;
};

export default ChatContainerWrapper;