import React from 'react';
import { Typography, Box, Rating } from '@mui/material';
import {
  Star as SatisfactionIcon,
  TrendingUp as GrowthIcon,
  Users as SupportIcon,
  Award as RecognitionIcon,
  HeartHandshake as BalanceIcon,
  AlertCircle as ChallengesIcon,
  BookOpen as OpportunitiesIcon,
  CheckCircle as CompellingIcon
} from 'lucide-react';
import { AdaptiveSectionLayout, DetailRow } from './AdaptiveSectionLayout.tsx';

// Reusable text section creator
const createTextSection = ({ icon, title, content }) => {
  if (!content) return null;
  return {
    icon,
    title,
    content: (
      <DetailRow>
        <Typography variant="body2">{content}</Typography>
      </DetailRow>
    )
  };
};

const SatisfactionOverview = ({ score, overview }) => {
  if (!score && !overview) return null;

  const content = (
    <>
      {score > 0 && (
        <Box sx={{ mb: 1.5 }}>
          <Rating 
            value={score}
            max={10}
            readOnly
            size="small"
            sx={{ '& .MuiRating-icon': { color: 'primary.main' } }}
          />
        </Box>
      )}
      {overview && <Typography variant="body2">{overview}</Typography>}
    </>
  );

  return { icon: SatisfactionIcon, title: "Overall Satisfaction", content };
};

const OpennessToOpportunities = ({ openness }) => {
  if (!openness && openness !== 0) return null;

  const getOpennessMessage = (score) => {
    if (score <= 3) return 'Not open to new opportunities';
    if (score <= 7) return 'May consider new opportunities';
    return 'Actively looking for new opportunities';
  };

  const content = (
    <DetailRow>
      <Typography variant="body2">{getOpennessMessage(openness)}</Typography>
      <Box sx={{ mt: 0.5 }}>
        <Rating
          value={openness}
          max={10}
          readOnly
          size="small"
          sx={{ '& .MuiRating-icon': { color: theme => theme.palette.info.main } }}
        />
      </Box>
    </DetailRow>
  );

  return { icon: OpportunitiesIcon, title: "Openness to Opportunities", content };
};

const CompellingFactors = ({ factors }) => {
  if (!factors?.length) return null;

  const content = (
    <Box component="ul" sx={{ m: 0, pl: 3 }}>
      {factors.map((factor, index) => (
        <Typography 
          key={index} 
          component="li" 
          variant="body2" 
          sx={{ mb: index !== factors.length - 1 ? 1 : 0 }}
        >
          {factor}
        </Typography>
      ))}
    </Box>
  );

  return { icon: CompellingIcon, title: "Compelling Factors", content };
};

const ProfessionalSatisfaction = ({ satisfaction_snapshots }) => {
  const satisfaction = satisfaction_snapshots[satisfaction_snapshots.length - 1];

  // Handle potential typo in 'openness_to_opportuinities'
  const opennessScore = satisfaction.openness_to_opportunities ?? 
                       satisfaction.openness_to_opportuinities;

  const sections = [
    SatisfactionOverview({ 
      score: satisfaction.satisfaction_score,
      overview: satisfaction.satisfaction_overview 
    }),
    createTextSection({
      icon: GrowthIcon,
      title: "Growth Opportunities",
      content: satisfaction.growth
    }),
    createTextSection({
      icon: SupportIcon,
      title: "Team Support",
      content: satisfaction.support
    }),
    createTextSection({
      icon: RecognitionIcon,
      title: "Recognition",
      content: satisfaction.recognition
    }),
    createTextSection({
      icon: BalanceIcon,
      title: "Work-Life Balance",
      content: satisfaction.balance
    }),
    createTextSection({
      icon: ChallengesIcon,
      title: "Current Challenges",
      content: satisfaction.challenges
    }),
    OpennessToOpportunities({ openness: opennessScore }),
    CompellingFactors({ factors: satisfaction.compelling_factors })
  ].filter(Boolean);

  if (sections.length === 0) return null;

  return <AdaptiveSectionLayout sections={sections} />;
};

export default ProfessionalSatisfaction;