import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Box, Alert } from '@mui/material';
import useAPIWithAuth from '../hooks/useAPIWithAuth.ts';
import AccountMenu from './sidebar/Logo.tsx';
import { useAuth } from '../hooks/useAuth.ts';

const WaitlistSignup = () => {
  const { user, isAuthenticated, hasAccount} = useAuth();
  const [reason, setReason] = useState('');
  const [linkedinUrl, setLinkedinUrl] = useState('');
  const [linkedinError, setLinkedinError] = useState('');
  const [success, setSuccess] = useState(false);
  const api = useAPIWithAuth();

  const validateLinkedinUrl = (url) => {
    if (!url) {
      return 'LinkedIn URL is required';
    }
    const linkedinPattern = /^https:\/\/([a-z]{2,3}\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return linkedinPattern.test(url) ? '' : 'Please enter a valid LinkedIn URL (e.g., https://www.linkedin.com/in/username/)';
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const error = validateLinkedinUrl(linkedinUrl);
    
    if (error) {
      setLinkedinError(error);
      return;
    } else {
      setLinkedinError('');
    }

    await api.post('/waitlist/', { reason, linkedin_url: linkedinUrl });
    setSuccess(true);
  };

  return (
    <>
      {isAuthenticated && (<AccountMenu />)}
      <Container maxWidth="sm">
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          {isAuthenticated && (
            <Typography variant="h6" component="h2" gutterBottom>
              Welcome, {user.name}!
            </Typography>
          )}
          <Typography variant="h4" component="h1" gutterBottom>
            Join the Nexco.ai waitlist
          </Typography>
          {success || hasAccount ? (
            <Alert severity="success">
              Thank you for signing up! We'll be in touch soon.
            </Alert>
          ) : (
            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="LinkedIn Profile URL"
                variant="outlined"
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
                error={!!linkedinError}
                helperText={linkedinError}
                required
                sx={{ mt: 2, mb: 2 }}
              />
              <TextField
                fullWidth
                label="Hwo did you hear about Nexco.ai?"
                multiline
                rows={2}
                variant="outlined"
                value={reason}
                required
                onChange={(e) => setReason(e.target.value)}
                sx={{ mt: 2, mb: 2 }}
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </form>
          )}
        </Box>
      </Container>
    </>
  );
};

export default WaitlistSignup;
