import {
  UserCheck as ProfileIcon,
  LockKeyhole as VaultIcon,
  ListTodo as NextStepsIcon,
  Bot as AIIcon,
} from 'lucide-react';
import { ComponentType } from 'react';
import LogoIcon from './components/LogoIcon.tsx';
import LoggedOutHome from './components/LoggedOutHome.tsx';
import Introduction from './components/introduction/Introduction.tsx';
import ChatWithProgress from './components/chat/ChatWithProgress.tsx';
import DataControlsInterstitial from './components/data_controls/DataControlsInterstitial.tsx';
import ExecutiveProfile from './components/executive_profile/ExecutiveProfile.tsx';
import Waitlist from './components/Waitlist.tsx';
import Dashboard from './pages/Dashboard.tsx';
import SharedProfile from './pages/SharedProfile.tsx';
import PrivateVault from './pages/PrivateVault.tsx';
import Achievements from './pages/Achievements.tsx';
import AIAssistant from './pages/AIAssistant.tsx';
import ComponentEditor from './pages/ComponentEditor.tsx';
import { PrivacyPolicy, TermsOfService } from './components/PolicyPages.tsx';

interface AuthRoute {
  path: string;
  Element: ComponentType<any>;
  props?: Record<string, any>;
  public?: boolean;
  requiresInvite?: boolean;
  requiresAccount?: boolean;
}

interface NavRoute {
  id: string;
  path: string;
  label: string;
  shortLabel?: string;
  icon: ComponentType;
  color?: string;
  headline?: string;
  description: string;
  cta?: string;
  Component: ComponentType;
}

export const AUTH_ROUTES: Record<string, AuthRoute> = {
  HOME: {
    path: '/',
    Element: LoggedOutHome,
    public: true
  },
  INTRODUCTION: {
    path: '/introduction',
    Element: Introduction,
    requiresInvite: true
  },
  ONBOARDING: {
    path: '/onboarding',
    Element: ChatWithProgress,
    props: { conversationType: 'onboarding' }
  },
  DATA_CONTROLS_INTERSTITIAL: {
    path: '/onboarding-data-controls',
    Element: DataControlsInterstitial,
    requiresAccount: true
  },
  ASPIRATIONS: {
    path: '/aspirations',
    Element: ChatWithProgress,
    props: { conversationType: 'aspirations' }
  },
  COMPONENT_EDITOR: {
    path: '/shared-profile/edit/:sectionId',
    Element: ComponentEditor
  },
  COMPONENT_EDITOR_WITH_EXPERIENCE: {
    path: '/shared-profile/edit/:sectionId/:experienceId',
    Element: ComponentEditor
  },
  SAMPLE_PROFILE: {
    path: '/sample-profile',
    Element: ExecutiveProfile,
    public: true
  },
  WAITLIST: {
    path: '/waitlist',
    Element: Waitlist,
    requiresAccount: false
  },
  PRIVACY: {
    path: '/privacy',
    Element: PrivacyPolicy,
    public: true
  },
  TERMS: {
    path: '/terms', 
    Element: TermsOfService,
    public: true
  }
};

export const NAV_ROUTES: Record<string, NavRoute> = {
  DASHBOARD: {
    id: 'dashboard',
    path: '/dashboard',
    label: 'Nexco.ai',
    icon: LogoIcon,
    description: 'Overview and insights',
    Component: Dashboard
  },
  SHARED_PROFILE: {
    id: 'shared-profile',
    path: '/shared-profile',
    label: 'Shared Profile',
    shortLabel: 'Profile',
    icon: ProfileIcon,
    color: '#00C2A8',
    headline: 'Get noticed by headhunters',
    description: 'Your shared professional profile, selectively shared with headhunters and internal search teams at the worlds largest companies',
    cta: 'Go to profile',
    Component: SharedProfile
  },
  PRIVATE_VAULT: {
    id: 'private-vault',
    path: '/private-vault',
    label: 'Private Vault',
    shortLabel: 'Vault',
    icon: VaultIcon,
    color: '#6A4C93',
    headline: 'Organise Career Records',
    description: 'Store and access your career essentials—CVs, references, contracts, and more—in a secure and private vault.',
    cta: 'Populate now',
    Component: PrivateVault
  },
  ACHIEVEMENTS: {
    id: 'achievements',
    path: '/achievements',
    label: 'Record achievements',
    shortLabel: 'Achievements',
    icon: NextStepsIcon,
    color: '#FF7600',
    headline: 'Record Your Impact',
    description: 'Create a portfolio of accomplishments to unlock promotions, opportunities, and career clarity.',
    cta: 'Record now',
    Component: Achievements
  },
  NEXCO_AI: {
    id: 'assistant',
    path: '/ai',
    label: 'AI assistant',
    shortLabel: 'Assist',
    icon: AIIcon,
    color: '#FFC300',
    headline: 'Level-Up with AI',
    description: 'Use AI for tailored support with writing CVs, collating annual review packs and more.',
    cta: 'Chat now',
    Component: AIAssistant
  }
};

export const NAV_ROUTES_ARRAY = Object.values(NAV_ROUTES);
export const MOBILE_NAV_ROUTES_ARRAY = [NAV_ROUTES_ARRAY[3], NAV_ROUTES_ARRAY[1], NAV_ROUTES_ARRAY[0], NAV_ROUTES_ARRAY[2], NAV_ROUTES_ARRAY[4]];
export const AUTH_ROUTES_ARRAY = Object.values(AUTH_ROUTES);