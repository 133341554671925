import React from 'react';
import { Box, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import ChatAndArtifactsContainer from '../components/chat/ChatAndArtifactsContainer.tsx';
import { ChatUI } from '../components/chat/ChatUI.tsx';
import { useSocket, useSocketMessages, useSocketProfile } from '../hooks/useSocket.ts';
import { ProfileSection } from '../components/profile/ProfileSection.tsx';
import { Profile, Experience } from '../types/profile.ts';

interface ChatData {
  messages: any[];
  isConnected: boolean;
  isLoading: boolean;
  onSendMessage: (message: string) => void;
}

interface ProfileData {
  data: Profile | { experience: Experience | undefined } | null;
  isLoading: boolean;
  isSaving: boolean;
}

const EditorChat = ({ data }: { data: ChatData }) => {
  const { messages, isConnected, isLoading, onSendMessage } = data;
  
  return (
    <ChatUI
      messages={messages}
      isConnected={isConnected}
      isLoading={isLoading}
      onSendMessage={onSendMessage}
      initialLoadComplete={messages.length > 0}
      isMobile={false}
    />
  );
};

const EditorProfile = ({ data }: { data: ProfileData }) => {
  const { sectionId } = useParams<{ sectionId: string }>();
  if (!sectionId) return null;
  return <ProfileSection sectionId={sectionId} data={data.data} />;
};

const ComponentEditor = () => {
  const { sectionId, experienceId } = useParams<{ sectionId: string; experienceId?: string }>();
  
  // Initialize socket connection for component editing
  const { socket, isConnected } = useSocket('edit_profile', {
    requireAuthentication: true,
    metadata: { sectionId, experienceId }
  });

  // Get messages and profile from socket
  const { messages, addUserMessage, isLoading } = useSocketMessages(socket);
  const { profile, isSaving } = useSocketProfile(socket);

  // Find the relevant experience if we have an experienceId
  const experience = experienceId && profile?.experiences?.find((exp: Experience) => exp.id === experienceId);
  const profileData = experienceId ? { experience } : profile;

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        maxHeight: `calc(100VH - 110px)`,
        bgcolor: 'background.default',
        p: { xs: 1, sm: 2 },
        alignItems: 'center'
      }}
    >
      <Paper 
        elevation={1} 
        sx={{ 
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '1200px',
          width: '100%'
        }}
      >
        <ChatAndArtifactsContainer
          chatData={{ messages, isConnected, isLoading, onSendMessage: addUserMessage }}
          profileData={{ data: profileData, isLoading: false, isSaving }}
          ChatComponent={EditorChat}
          ProfileComponent={EditorProfile}
        />
      </Paper>
    </Box>
  );
};

export default ComponentEditor; 