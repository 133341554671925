import React from 'react';
import { Typography, Box, Slider } from '@mui/material';
import {
  Target as ClarityIcon,
  Brain as DNAIcon
} from 'lucide-react';
import EmptyState from './EmptyState.tsx';
import { AdaptiveSectionLayout } from './AdaptiveSectionLayout.tsx';

const ConfidenceSlider = ({ score }) => (
  <Box sx={{ mt: 2 }}>
    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
      Confidence Level
    </Typography>
    <Slider
      value={[score - 1, score + 1]}
      disabled
      min={1}
      max={10}
      marks
      sx={{ mx: 1 }}
    />
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
      <Typography variant="caption" color="text.secondary">
        Entirely unsure
      </Typography>
      <Typography variant="caption" color="text.secondary">
        Completely confident
      </Typography>
    </Box>
  </Box>
);

const ProfessionalIdentity = ({ professional_dna }) => {
  if (!professional_dna) return null;

  const content = (
    <Typography variant="body2">
      {professional_dna}
    </Typography>
  );

  return { icon: DNAIcon, title: "Professional DNA", content };
};

const FuturePlans = ({ clarity_score, clarity_reasoning }) => {
  if (!clarity_score && !clarity_reasoning) return null;

  const content = (
    <>
      <Typography variant="body2">
        {clarity_reasoning}
      </Typography>
      {clarity_score && <ConfidenceSlider score={clarity_score} />}
    </>
  );

  return { icon: ClarityIcon, title: "Future Plans", content };
};

const ProfessionalDNA = ({ plans_and_dna }) => {
  if (!plans_and_dna || !Object.values(plans_and_dna).some(Boolean)) {
    return (
      <EmptyState
        icon={DNAIcon}
        title="No professional DNA defined"
        description="Share insights about your career direction, role clarity, and professional identity to help find the right opportunities"
      />
    );
  }

  const { 
    next_role_clarity_score,
    next_role_clarity_reasoning,
    professional_dna
  } = plans_and_dna;

  const sections = [
    ProfessionalIdentity({ professional_dna }),
    FuturePlans({ 
      clarity_score: next_role_clarity_score,
      clarity_reasoning: next_role_clarity_reasoning 
    })
  ].filter(Boolean);

  // Removed Paper wrapper - now managed by parent components
  return <AdaptiveSectionLayout sections={sections} />;
};

export default ProfessionalDNA;