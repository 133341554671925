import React, { useRef, useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Box,
  Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProfileContainer } from './ProfileContainer.tsx';
import { useProfileSections } from './useProfileSections.ts';
import DataControlsBanner from '../data_controls/DataControlsBanner.tsx';
import SavingStatusBanner from './SavingStatusBanner.tsx';

interface ProfileData {
  profile?: {
    stage?: string;
    data_control?: any;
  };
  isSaving?: boolean;
}

const ProfileAccordion = ({ data = {} as ProfileData }) => {
  const [expandedSections, setExpandedSections] = useState<string[]>([]);
  const activeCardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data?.profile && activeCardRef.current) {
      activeCardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [data?.profile]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedSections(prev => {
      if (isExpanded) {
        return [...prev, panel];
      } else {
        return prev.filter(p => p !== panel);
      }
    });
  };

  const sections = useProfileSections(data?.profile || {});

  const getCurrentStageIndex = () => {
    const stageKey = data?.profile?.stage;
    if (!stageKey) return -1;
    if (stageKey.includes(' - ')) {
      const [org] = stageKey.split(' - ');
      return sections.findIndex(
        (section) =>
          section.stage === 'employment' &&
          section.props.experiences?.some((exp) => exp.organisation === org)
      );
    }
    return sections.findIndex((section) => section.stage === stageKey);
  };

  const shouldExpand = (section, stageKey) => {
    if (!stageKey) return false;
    if (stageKey.includes(' - ')) {
      const [org] = stageKey.split(' - ');
      return (
        section.stage === 'employment' &&
        section.props.experiences?.some((exp) => exp.organisation === org)
      );
    }
    return section.stage === stageKey;
  };

  const isStageActive = (section, stageKey) => {
    const currentIndex = getCurrentStageIndex();
    return sections.indexOf(section) === currentIndex;
  };

  if (!data?.profile) {
    return <div>Loading...</div>;
  }

  return (
    <ProfileContainer>
      {/* Control banners get their own Paper */}
      <Paper elevation={1} sx={{ mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Stack spacing={2}>
            <DataControlsBanner dataControl={data?.profile?.data_control} />
            <SavingStatusBanner isSaving={data?.isSaving}/>
          </Stack>
        </Box>
      </Paper>

      {/* Sections */}
      <Stack spacing={2}>
        {sections.map((section) => {
          const isExpanded = expandedSections.includes(section.stage) || 
                           shouldExpand(section, data?.profile?.stage);
          
          return (
            <Accordion
              key={section.stage}
              expanded={isExpanded}
              onChange={handleChange(section.stage)}
              ref={isStageActive(section, data?.profile?.stage) ? activeCardRef : null}
              disableGutters
              elevation={0}
              sx={{
                '&:before': { display: 'none' },
                bgcolor: 'common.white'
              }}
            >
              <AccordionSummary 
                expandIcon={<ExpandMoreIcon />}
                sx={{ 
                  bgcolor: 'common.white'
                }}
              >
                <Typography>{section.title}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, bgcolor: 'common.white' }}>
                  <Box sx={{ p: 2 }}>
                    <section.component {...section.props} currentStage={data?.profile?.stage} noPaper={true}/>
                  </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    </ProfileContainer>
  );
};

export default ProfileAccordion;