import React, { useMemo, useCallback } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChatAndArtifactsContainer from './ChatAndArtifactsContainer.tsx';
import { ChatContainer } from './ChatContainer.tsx';
import ProfileAccordion from '../profile/ProfileAccordion.tsx';
import LinearProgressWithLabel from './LinearProgressWithLabel.tsx';
import { useSocket, useSocketMessages, useSocketProfile, useSocketProgress } from '../../hooks/useSocket.ts';

const ChatWithProgress = ({conversationType}) => {
  const navigate = useNavigate();
  
  const { socket, isConnected } = useSocket(conversationType, {
    requireAuthentication: true
  });

  const { messages, addUserMessage, isSaving, isLoading } = useSocketMessages(socket);
  const { profile } = useSocketProfile(socket);
  const { progress, changeStage } = useSocketProgress(socket);
  const totalStages = Object.values(progress || {}).length;
  const showProgress = totalStages > 1;

  // Handle chat completion
  const handleChatComplete = useCallback(() => {
    if(conversationType === "onboarding") {
      navigate('/onboarding-data-controls');
    }
    else if (conversationType === "achievements") {
      // navigate('/shared-profile');
    }
  }, [conversationType, navigate]);

  // Stable data references
  const chatData = useMemo(() => ({ 
    messages, isConnected, addUserMessage, isLoading 
  }), [messages, isConnected, addUserMessage, isLoading]);
  
  const profileData = useMemo(() => ({ 
    profile, changeStage, isSaving 
  }), [profile, changeStage, isSaving]);

  const RenderChatContainer = useCallback((props) => <ChatContainer {...props} />, []);
  const RenderProfileAccordion = useCallback((props) => <ProfileAccordion {...props} />, []);

  return (
    <Box sx={{ 
            display: 'flex', 
            flex: 1,
            flexDirection: 'column'
          }}>
      {showProgress ? (
        <>
          <Box sx={{
                    height: '52px',
                    width: '100%',          
                    position: 'fixed',
                    top: { xs: 'auto', md: 60 },
                    bottom: { xs: 64, md: 'auto' },
                    zIndex: 1099
                  }}>
            <LinearProgressWithLabel 
              progress={progress} 
              onChatCompleted={handleChatComplete}
            />
          </Box>
          <Box data-testid="chat-and-artifacts-container" sx={{ 
            marginTop: { xs: 0, md: '52px' },
            marginBottom: { xs: '52px', md: 0 }
          }}>
            <ChatAndArtifactsContainer
              chatData={chatData}
              profileData={profileData}
              ChatComponent={RenderChatContainer}
              ProfileComponent={RenderProfileAccordion}
            />
        </Box>
      </>
    ):(
      <ChatAndArtifactsContainer
        chatData={chatData}
        profileData={profileData}
        ChatComponent={RenderChatContainer}
        ProfileComponent={RenderProfileAccordion}
      />
    )}
    </Box>
  );
};

export default ChatWithProgress;