// components/chat/Progress.tsx
import React from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon,
  ListItemButton,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/TaskAltRounded';
import EllipsesIcon from '@mui/icons-material/MoreHorizTwoTone';
import EditingIcon from '@mui/icons-material/AppRegistrationTwoTone';

interface ProgressStageProps {
  stageKey: string;
  status: 'completed' | 'active' | 'in_progress' | string;
  onStageClick: (stage: string) => void;
}

interface ProgressProps {
  summary: [string, string][];
  onStageClick: (stage: string) => void;
}

const ProgressStage: React.FC<ProgressStageProps> = React.memo(({ stageKey, status, onStageClick }) => {
  const theme = useTheme();
  
  const getIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <CheckIcon color="success" />;
      case 'active':
        return <EditingIcon color="primary" />;
      case 'in_progress':
        return <EditingIcon color="action" />;
      default:
        return <EllipsesIcon color="action" />;
    }
  };

  const getItemStyle = (status: string) => ({
    '&.Mui-disabled': {
      opacity: 0.6,
      cursor: 'not-allowed'
    },
    '&:hover': {
      bgcolor: 'action.hover'
    },
    ...(status === 'active' && {
      bgcolor: theme.palette.primary.light + '20',
    })
  });

  return (
    <ListItemButton
      onClick={() => onStageClick(stageKey)}
      sx={getItemStyle(status)}
    >
      <ListItemText
        primary={`${stageKey.charAt(0).toUpperCase()}${stageKey.slice(1)}`}
        primaryTypographyProps={{
          variant: 'body2',
          color: status === 'active' ? 'primary' : 'inherit'
        }}
      />
      <ListItemIcon sx={{minWidth: 32}}>
        {getIcon(status)}
      </ListItemIcon>
    </ListItemButton>
  );
});

export const Progress: React.FC<ProgressProps> = ({ summary = [], onStageClick }) => {
  return (
    <List>
      {summary.length > 0 ? (
        summary.map(([key, status]) => (
          <ProgressStage
            key={key}
            stageKey={key}
            status={status}
            onStageClick={onStageClick}
          />
        ))
      ) : (
        <ListItem>
          <ListItemText primary="No progress data available..." />
        </ListItem>
      )}
    </List>
  );
};