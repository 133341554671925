import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, useTheme } from '@mui/material';
import { useAuth } from "../hooks/useAuth.ts";
import { useNavigate } from 'react-router-dom';
import './auth0-overrides.css';
import { Auth0Lock, Auth0LockPasswordless } from 'auth0-lock';
import authConfig from '../auth_config.json';
import { useProfile } from '../hooks/useProfile.ts';
const { domain, clientId, audience, configurationBaseURL } = authConfig;

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { login } = useAuth();
  const { profile } = useProfile(); 
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    if (isAuthenticated && profile) {
      if (profile.onboarding_state === "in_progress") {
        navigate('/onboarding');
      } else if (profile.onboarding_state === "data_control") {
        navigate('/onboarding-data-controls');
      } else {
        navigate('/dashboard');
      }
    }
  }, [isAuthenticated, profile, navigate]);

  useEffect(() => {
    const handleAuthentication = (lock) => {
      lock.on('authenticated', async (authResult) => {
        await login(authResult);
        setIsAuthenticated(true);
      });
    };
    const AUTH_SETTINGS = {
      redirectUrl: window.location.origin,
      redirect: true,
      responseType: 'token id_token',
      params: {
        audience: audience,
        scope: 'openid profile email offline_access'
      }
    };
    const THEME_SETTINGS = {
      logo: '',
      primaryColor: theme.palette.primary.main,
      authButtons: {
        "linkedin": {
          primaryColor: "#FFF",
          foregroundColor: "#000000",
          borderColor: "#000",
          icon: '/linkedin.svg'
        },
      }
    };

    // Social login setup
    const socialContainer = document.getElementById('social-login-container');
    let socialLock, passwordlessLock;
    if (socialContainer) {
      socialLock = new Auth0Lock(
        clientId,
        domain,
        {
          audience: audience,
          configurationBaseUrl: configurationBaseURL,
          theme: THEME_SETTINGS,
          allowedConnections: ['google-oauth2', 'linkedin', 'windowslive'],
          auth: AUTH_SETTINGS,
          container: 'social-login-container',
          languageDictionary: {
            title: '',
            loginWithLabel: 'Continue with %s'
          }
        }
      );
      handleAuthentication(socialLock);
      socialLock.show();
    }

    // Passwordless login setup
    const passwordlessContainer = document.getElementById('passwordless-container');
    if (passwordlessContainer) {
      passwordlessLock = new Auth0LockPasswordless(
        clientId,
        domain,
        {
          audience: audience,
          configurationBaseUrl: configurationBaseURL,
          theme: THEME_SETTINGS,
          allowedConnections: ['email'],
          auth: AUTH_SETTINGS,
          container: 'passwordless-container',
          languageDictionary: {
            title: "or",
            signUpTerms: "I agree to the <a href='/terms' target='_new'>terms of service</a> and <a href='/privacy' target='_new'>privacy policy</a>.",
            emailInputPlaceholder: 'Enter your personal email',
            submitLabel: 'Continue with email',
            passwordlessEmailInstructions: ''
          }
        }
      );
      handleAuthentication(passwordlessLock);
      passwordlessLock.show();
    }
    return () => {
      if (socialLock) socialLock.hide();
      if (passwordlessLock) passwordlessLock.hide();
    };
  }, [login, navigate, theme.palette.primary.main]);

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Grid container sx={{ height: '100%', m: 0, width: '100%' }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            bgcolor: { xs: 'transparent', md: '#fff9e6' },
            p: { xs: 0, md: 4 },
            justifyContent: 'center',
            alignItems: 'center',
            height: { xs: 'auto', md: '100%' },
            display: 'flex'
          }}
        >
          <Box>
            <Box
              component="img"
              src="/logo.svg"
              alt="Nexco.ai"
              sx={{
                height: { xs: 50, md: 80 },
                ml: { xs: 0.5, md: 0 },
                mt: { xs: 2.5, md: 0 },
                marginRight: '10px'
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: { xs: 20, md: 26 },
                color: theme.palette.primary.main,
                ml: { xs: 2.5, md: 3.25 }
              }}
            >
              The AI assistant for Financial Officers looking to maximise career growth
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            order: { xs: 2, md: 2 }
          }}
        >
          <Box 
            mt={2} 
            sx={{ 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}
          >
            <div id="social-login-container"></div>
            <div id="passwordless-container"></div>
          </Box>
        </Grid>
      </Grid>
      <Box
        sx={{
          position: 'absolute',
          bottom: 4,
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: '20px'
        }}
       >
        <Typography variant="body2" color="textSecondary" sx={{ width:200, textAlign: 'right' }}>
          <a href="/terms" style={{ textDecoration: 'none', color: 'inherit' }}>Terms of use</a>
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ width: 200, textAlign: 'left' }}>
          <a href="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>Privacy policy</a>
        </Typography>
       </Box>
    </Container>
  );
};

export default HomePage;