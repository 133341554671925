import { Paper, Typography, CircularProgress } from '@mui/material';
import { Check } from 'lucide-react';
const SavingStatusBanner = ({ isSaving }) => {
    
    return (
      <Paper 
        elevation={0}
        sx={{
          backgroundColor: isSaving ? 'warning.lighter' : 'success.lighter',
          display: 'flex',
          alignItems: 'center',
          gap: 1
        }}
      >
        {isSaving ? (
          <>
            <CircularProgress size={16} />
            <Typography>Saving changes...</Typography>
          </>
        ) : (
          <>
            <Check size={16} />
            <Typography>All changes saved</Typography>
          </>
        )}
      </Paper>
    );
   };
  export default SavingStatusBanner;