import React from 'react';
import { Typography, Box, Chip } from '@mui/material';
import {
  Building2 as OfficeIcon,
  Timer as CommuteIcon,
  Calendar as PatternIcon,
  Plane as InternationalIcon,
  Train as DomesticIcon,
  MapPin as LocationIcon
} from 'lucide-react';
import { AdaptiveSectionLayout, DetailRow } from './AdaptiveSectionLayout.tsx';

const WorkPatternChip = ({ pattern }) => {
  const patterns = {
    'fully_remote': { label: 'Remote', color: 'success' },
    'hybrid': { label: 'Hybrid', color: 'info' },
    'office_based': { label: 'Office-based', color: 'warning' }
  };

  const config = patterns[pattern] || { label: pattern, color: 'default' };

  return (
    <Chip
      label={config.label}
      color={config.color}
      size="small"
      variant="outlined"
      sx={{ height: 24 }}
    />
  );
};

const LocationDisplay = ({ location }) => {
  if (!location) return null;

  const locationParts = [
    location.city,
    location.region,
    location.country
  ].filter(Boolean);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body2">
        {locationParts.join(', ')}
      </Typography>
      {location.details && (
        <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
          ({location.details})
        </Typography>
      )}
    </Box>
  );
};

const CurrentWorkSetup = ({ current }) => {
  if (!current) return null;

  const content = (
    <>
      {current.working_pattern && (
        <DetailRow icon={PatternIcon}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <WorkPatternChip pattern={current.working_pattern} />
            {current.pattern_details && (
              <Typography variant="body2" color="text.secondary">
                {current.pattern_details}
              </Typography>
            )}
          </Box>
        </DetailRow>
      )}

      {current.office_location && (
        <DetailRow icon={LocationIcon}>
          <LocationDisplay location={current.office_location} />
        </DetailRow>
      )}

      {current.current_commute && (
        <DetailRow icon={CommuteIcon}>
          <Typography variant="body2">
            {current.current_commute}
          </Typography>
        </DetailRow>
      )}
    </>
  );

  return { icon: OfficeIcon, title: "Current Work Setup", content };
};

const TravelRequirements = ({ travel }) => {
  if (!travel?.domestic_frequency && !travel?.international_frequency) return null;

  const content = (
    <>
      {travel.domestic_frequency && (
        <DetailRow icon={DomesticIcon}>
          <Typography variant="body2">
            Domestic Travel: {travel.domestic_frequency}
          </Typography>
        </DetailRow>
      )}

      {travel.international_frequency && (
        <DetailRow icon={InternationalIcon}>
          <Typography variant="body2">
            International Travel: {travel.international_frequency}
          </Typography>
        </DetailRow>
      )}
    </>
  );

  return { icon: InternationalIcon, title: "Travel Requirements", content };
};

const RoleArrangementsDetails = ({ arrangements_and_travel }) => {
  if (!arrangements_and_travel) return null;

  const sections = [
    CurrentWorkSetup({ current: arrangements_and_travel.current }),
    TravelRequirements({ travel: arrangements_and_travel.travel })
  ].filter(Boolean);

  if (sections.length === 0) return null;

  return <AdaptiveSectionLayout sections={sections} />;
};

export default RoleArrangementsDetails;