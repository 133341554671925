import React from 'react';
import { Drawer } from '@mui/material';

const Sidebar = ({ 
  mobileOpen, 
  handleDrawerToggle,
  drawerWidth,
  content
}) => {

  return (
    <>
      {/* Mobile Drawer */}
      <Drawer
        variant={'temporary'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          mb: 20,
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {content}
      </Drawer>

      {/* Desktop Drawer */}
      <Drawer
        variant="permanent"
        PaperProps={{
          style: {
            borderRight: 'none',
          },
        }}
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          mt: 40,
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, mt:7 },
        }}
        open
      >
        {content}
      </Drawer>
    </>
  );
};

export default Sidebar;
